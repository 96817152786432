import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../asset/style/Creation.css';
import Fade from 'react-reveal/Fade';
import $ from 'jquery';
import {creation_ajax} from '../components/API.js';
import Loading from '../components/Loading.js';
import Preview from '../asset/img/preview_icon.png';
import Plus from '../asset/img/plus_icon.png';

export default class CreationDoc extends Component {
	constructor(props){
		super(props);
		this.handleFetchData = this.handleFetchData.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.state = {
			loadingModal:false,
			selected: [],
		};
	}	

	

	componentDidMount(){
		this.handleFetchData();
	}

	componentDidUpdate(prevProps, prevState){
		if(prevProps.activeChild !== this.props.activeChild){
			this.handleFetchData();
		}
	}

	handleFetchData(){
		this.handleInput('loadingModal',true);
		$.ajax({
			url:creation_ajax,
			type:"POST",
			data: {getDocuments:this.props.activeChild}
		}).done((json_data)=>{
			const data = JSON.parse(json_data);
			this.setState({documents:data["data"]});
		}).always(()=>{
			this.handleInput('loadingModal',false);
		});
	}

	handleInput(state,newValue){
		const newState = {};
		newState[state]=newValue;
		this.setState(newState);
	}

	render(){
		let doc;
		if(this.state.documents !== undefined){
			const docObject = Object.keys(this.state.documents).map((key)=>{
				if(this.props.parentDoc !== undefined){
					if(this.props.toc[this.props.parentDoc].indexOf(this.state.documents[key].Name) == -1){
						if(this.state.documents[key].Name.split('.').pop()==='pdf'){
							return this.state.documents[key];
						}
						
					}
				}

			});


			doc = docObject.map((data,i)=>{
				if(data !== undefined){
					return (
						<div key={i} name={i} className='fl w-100 cf bg-black-20 bb'>
							<div className={'fl w-60 creationDocHeader pv2 '} id={data.PartitionKey}>
								<span className='w-100 tc' name={i}>{data.Name}</span>
							</div>
							<div className={'fl w-20 creationDocHeader shadow-click br bl b--white pa2'} id={data.Name} rowid={i} onClick={this.props.previewDoc} style={{display:'flex',justifyContent:'center'}}>
					
								<img src={Preview} alt='Preview' style={{height:'25px', width:'auto'}}/>
						
							</div>
							<div className={'fl w-20 creationDocHeader shadow-click pa2'} href={this.props.parentDoc}  onClick={this.props.addDoc} style={{display:'flex',justifyContent:'center'}}>
								<img src={Plus} id={data.Name}  alt='Add'  style={{height:'25px', width:'auto'}}/>
							</div>
						</div>
					);
				}
			});
		}else{
			doc = null;
		}
		return (
			<div>
				<Loading toggleModal={this.handleInput} state={this.state.loadingModal} alt="Loading Documents..."/>
				<div className='creationDiv w-50 fl ph3 cf'>
					<div className='boxShadow br3 cf'>
						<div className='creationHeader br3 br--top f4-ns f6 ba b--dark-gray'>
							<span className='tc w-100 '><span className='word-break'>Product</span> Documents</span>
						</div>
						<div className='fl w-100 overflow-y-scroll' >
							<div className='fl w-60 creationHeader bg-dark-gray'>
								<span className='w-100 tc'>Documents</span>
							</div>
							<div className='fl w-20 creationHeader bg-dark-gray br bl b--white'>
								<span className='w-100 tc'>Preview</span>
							</div>
							<div className='fl w-20 creationHeader bg-dark-gray'>
								<span className='w-100 tc'>+</span>
							</div>
						</div>
						<div className="fl w-100 overflow-scroll creationHeight f6">
							{doc}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreationDoc.propTypes={
	activeChild: PropTypes.string.isRequired,
	previewDoc: PropTypes.func.isRequired,
	addDoc: PropTypes.func.isRequired,
	parentDoc: PropTypes.string.isRequired,
	toc: PropTypes.object.isRequired
}