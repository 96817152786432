import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Modal from 'react-modal';
import '../asset/style/Creation.css';
import $ from 'jquery';
import {creation_ajax} from '../components/API.js';
import Loading from '../components/Loading.js';

Modal.setAppElement('#root');

const modalStyles = {
	content:{
		top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    transform: 'translate(-50%, -50%)',
	    backgroundBack: 'transparent',
	   
	},
	overlay: {
		zIndex:2
	}
}

export default class Upload extends Component {
	constructor(props){
		super(props);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.state = {
			fileupload: false,
			filedetails: 'No PDF has been uploaded.',
			loadingModal: false,
		}
	}

	handleUpload(e){
		const files = e.target.files;
		const id = e.currentTarget.id;
		const label_id = id + "_label";
		const x = document.getElementsByName(label_id);
		const y = document.getElementsByName(id);


		let successdetails = [];
		for(var i = 0, file; file = files[i]; i++){
			if(file !== undefined){
				if(file.type !== 'application/pdf'){
					x[0].style.color = 'red';
					y[0].style.borderColor = 'red';
					let filedetails = "Not all files are PDF. Please upload only PDF documents.";
					this.setState({'fileupload':false, 'filedetails':filedetails});
					return;
				}else{
					const fileDate = file.lastModifiedDate ? file.lastModifiedDate.toLocaleDateString() : 'n/a';
					const fileType = file.type ? file.type : 'n/a'
					
					successdetails.push(file.name+' ('+fileType+') - '+file.size+' bytes, last modified: '+ fileDate);
				}
			}else{
				x[0].style.color = 'red';
				y[0].style.borderColor = 'red';
				this.setState({'fileupload':false, 'filedetails':'No PDF has been uploaded.'});
				return;
			}
		}
		x[0].style.color = '';
		y[0].style.borderColor = '';
		this.setState({'fileupload':files, 'filedetails':successdetails});

	}

	handleSubmit(){
		if(this.state.fileupload){
			this.handleInput('loadingModal',true);
			let formData = new FormData();
			$.each(this.state.fileupload, function(key,value){
				formData.append(key,value);
			});

			formData.append('projectname',this.props.projectName);
			formData.append('loginInfo',JSON.stringify(this.props.loginInfo));
			$.ajax({
				url: creation_ajax+"?uploadedfile",
				type: 'POST',
				data: formData,
				contentType:false,
				processData:false,
				cache:false
			}).done((json_data)=>{
				const data = JSON.parse(json_data);
				const upload_status = data;

				this.props.handleUserDoc(upload_status.success_upload);
				if(upload_status.failed_upload.length>0){
					this.setState({'fileupload':false,'filedetails':'Not all of the files were uploaded. Please try again with a different file.'});
				}else{
					this.setState({'fileupload':false,'filedetails':'All of the files are uploaded.'});
				}
				this.handleCloseModal();
			}).always(()=>{
				this.handleInput('loadingModal',false);
			});
		}
	}

	handleCloseModal(){
		this.props.toggleModal('uploadModal',false);
	}

	handleInput(state,newValue){
		const newState = {};
		newState[state]=newValue;
		this.setState(newState);
	}
	render(){
		let fileDetails
		if(this.state.fileupload){
			fileDetails = this.state.filedetails.map((data,i)=>{
				return <li key={i}>{data}</li>;
			});
		}else{
			fileDetails = <li>{this.state.filedetails}</li>;
		}
		return (
			<div>
				
				<Modal
				isOpen={this.props.state}
				contentLabel="Upload User Documents"
				onRequestClose={this.handleCloseModal}
				style={modalStyles}
				shouldCloseOnOverlayClick={false}>
				<Loading toggleModal={this.handleInput} state={this.state.loadingModal} alt="Loading Documents..."/>
					<div className='fl w-100 cf pa0 relative overflow-hidden' style={{width:'60vw',height:'60vh', minHeight:'16rem'}}>
						<div className='creationHeader pv2 f3 br2'>
							<span className='tc w-100'>Upload User Documents</span>
						</div>

						<div className='fl w-100 ph3 mt4'>
							<div className='fl w-100 f5'>
								<label htmlFor="userUpload" name="userUpload_label"><b>File Upload<span className='red'>*</span>:</b><span className='red'>(PDF Only 5MB Limit)</span></label>
							</div>
							<div className='fl w-100 pt2'>
								<input type='file' id='userUpload' name='userUpload' className='br2 ba bg-white w-100 fileUpload pv1' onChange={this.handleUpload} multiple/>
							</div>
						</div>

						<div className='fl w-100 ph3 pt3 mt2'>
							<div className='fl w-100 f5'>
								<label htmlFor="fileinfo" name='fileinfo_label'><b>File Information:</b></label>
							</div>
								<div className='fl w-100 overflow-auto h4' >
									<i><ul>{fileDetails}</ul></i><br/>
								</div>
						</div>


						<div className='w-100 mt2 absolute bottom-0'>
							<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click' onClick={this.handleSubmit} >Upload</button>
							<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click' onClick={this.handleCloseModal}>Close Upload</button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

Upload.propTypes = {
	state: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired,
	loginInfo: PropTypes.object.isRequired,
	projectName: PropTypes.string.isRequired,
	handleUserDoc: PropTypes.func.isRequired
}