export default function downloadPDF(url){
	//Anything but IE works here
	if(undefined === window.navigator.msSaveOrOpenBlob){
		var pom = document.createElement('a');
		pom.setAttribute('href',url);
		pom.setAttribute('target', '_blank');

		if (document.createEvent) {
			var event = document.createEvent('MouseEvents');
			event.initEvent('click', true, true);
			pom.dispatchEvent(event);
			pom.remove();
		}
		else {
			pom.click();
			document.body.removeChild(pom);
		}

	}
	
	//IE-Specific Code
	else{
		const pom = window.open(url,'_blank');
		pom.document.close();
		pom.close();
	}
}