import React, { Component } from 'react';
import '../asset/style/App.css';
import Login from './Login.js';
import Registration from './Registration.js';
import Submittal from './Submittal.js';
import background from '../asset/img/SPARKLES_PROGRESS_background.png';

class App extends Component {
  constructor(props){
    super(props);
    this.navigation = this.navigation.bind(this);
    this.login = this.login.bind(this);
    this.state = { 
      Pages: { 
        Login : true , 
        Registration: false , 
        Submittal: false
      },
      // loginInfo: {
      //   UID: "TVElM0QlM0Q=",
      //   completeSub: "TVElM0QlM0Q=",
      //   firstName: "WVdSdGFXNCUzRA==",
      //   lastName: "WVdSdGFXNCUzRA==",
      //   project_cap: "TWpVJTNE",
      //   project_count: "TVElM0QlM0Q=",
      //   token: "WVdSemFHWXlNemxtYURrek1tWmthVE14"
      // }
      loginInfo: {}
    };

  }


  navigation(navState,navAway,navTo){
    const newState = {};
    let updatedNavState = navState;
    updatedNavState[navAway] = false;
    updatedNavState[navTo] = true;
    newState['Pages'] = updatedNavState;
    if(navTo == 'Login'){
      newState['loginInfo'] = {};
    }
    this.setState(newState);
  }

  login(loginInfo){
    this.setState({loginInfo: loginInfo});
  }

  render(){
    document.body.style.backgroundImage = 'url(' + background + ')';

    return (
      <div>
        <Login pageActive={this.state.Pages} navigation={this.navigation} login={this.login} />
        <Registration pageActive={this.state.Pages} navigation={this.navigation} />
        <Submittal pageActive={this.state.Pages} navigation={this.navigation} login={this.state.loginInfo} updateLogin={this.login} />
      </div>
    );
  }
}

export default App;
