import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../asset/style/Login.css';
import img from '../asset/img/Wizard-submittal.jpg';
import Input from '../components/Input.js';
import {login_ajax} from '../components/API.js';
import $ from 'jquery';
import Fade from 'react-reveal/Fade';
import Loading from '../components/Loading.js';

export default class Login extends Component{
	constructor(props){
		super(props);
		this.handleRegistration = this.handleRegistration.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.state = {
			username_error:null,
			password_error:null,
			loadingModal:false,
		};
	}

	handleRegistration(){
		this.props.navigation(this.props.pageActive,'Login','Registration');
	}

	handleInput(state,newValue){
		const newState = {};
		newState[state]=newValue;
		this.setState(newState);  
	}
	
	handleEnter(e){
		if(e.key==='Enter'){
			this.handleLogin();
		}
	}

	handleLogin(){
		let username_set = false;
		let password_set = false;
		if(this.state.username !== undefined && this.state.username.trim() !== ''){
			username_set = true;
			document.getElementById('username').style.borderColor = '';
			this.setState({username_error:null});
		}else{
			document.getElementById('username').style.borderColor = 'red';
			this.setState({username_error:'red'});
		}
		if(this.state.password !== undefined && this.state.password.trim() !== ''){
			password_set = true;
			document.getElementById('password').style.borderColor = '';
			this.setState({password_error:null});
		}else{
			document.getElementById('password').style.borderColor = 'red';
			this.setState({password_error:'red'});
		}

		

		if(username_set && password_set){
			this.handleInput('loadingModal',true);
			$.ajax({
				url:login_ajax,
				data: {loginInfo:JSON.stringify({username:this.state.username, password:this.state.password})},
				type: "POST"
			}).done((json_data)=>{
				const data = JSON.parse(json_data);
				if(typeof(data)==='object'){
					if(data.status==='complete'){
						this.props.login(data.userInfo);
						this.props.navigation(this.props.pageActive,'Login','Submittal');
					}else{
						document.getElementById('username').style.borderColor = 'red';
						
						document.getElementById('password').style.borderColor = 'red';
						this.setState({username_error:'red','password_error':'red'});
		
					}
				}
			
			}).always((data)=>{
				this.handleInput('loadingModal',false);
			});
			
		}else{
		
		}
	}

	render(){
		if(this.props.pageActive.Login){
			return (
				<div>
					<Fade>
						<Loading toggleModal={this.handleInput} state={this.state.loadingModal} alt="Loading Documents..."/>
						<div className=' w-auto pa3 br3 divsection'>
							<div className=''>
								<div className='fl w-50-pic'>
									<img src={img} alt='pic' style={{boxShadow: '10px 5px 20px .5px rgba(0,0,0,0.6)'}}/>
								</div>
								<div className='fl w-50-login'>
									<h1>Welcome!</h1>
									<p><i>If you have an existing account please log in below.</i></p>
									
									<div className='fl w-100 f5'>
										<label htmlFor="username" className={this.state.username_error}><b>Username:</b></label>
									</div>
									<div className='fl w-100 pv2'>
										<Input id='username' name="username" className="br2 w-100 pa1" onChange={this.handleInput} onEnter={this.handleEnter}/>
									</div>

									<div className='fl w-100 f5'>
										<label htmlFor="password" className={this.state.password_error}><b>Password:</b></label>
									</div>
									<div className='fl w-100 pv2'>
										<Input type="password" id="password" name="password" className='br2 w-100 pa1' onChange={this.handleInput} onEnter={this.handleEnter}/>
									</div>

									<div className='fl w-100 pt2'>
										<div className='fl w-50'>
			
											<button className='bg-bottom f5 link dim br3 pv2 mb2 white w-100' onClick={this.handleLogin} style={{ backgroundColor : '#1965A3' }}>Login</button>
					
										</div>
										<div className='fl w-50'>
											<button className='bg-bottom f5 link dim br3 pv2 mb2 white w-100' onClick={this.handleRegistration} style={{ backgroundColor : '#1965A3' }}>Register</button>
										</div>
									</div>

									<div className="tc f5 fw4">
										<a href="#">Terms & Conditions</a>
										<p className='red b f6'>For best results, please use Chrome or Firefox as your browser.</p>
									</div>
								</div>
							</div>
						</div>
					</Fade>

				</div>
			);
		}else{
			return null;
		}
	}
}

Login.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired
}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             