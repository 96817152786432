import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {utoa, atou} from './Encode.js';

export default class Input extends Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e){
		
		const value = this.props.encode ? utoa(e.target.value) : e.target.value;
		this.props.onChange(this.props.name,value);
	}

	render(){
		if(this.props.value !== undefined){
			if(this.props.encode){
				if(this.props.onChange !== undefined){
					return (
						<div>
							<input
							type={this.props.type}
							name={this.props.name}
							id={this.props.id}
							onChange={this.handleChange}
							onKeyPress={this.props.onEnter}
							className={this.props.className}
							placeholder={this.props.placeholder}
							value={atou(this.props.value)}
							style={this.props.style}
							disabled={this.props.disabled}
							/>
						</div>
					);
				}else{
					return (
						<div>
							<input
							type={this.props.type}
							name={this.props.name}
							id={this.props.id}
							onKeyPress={this.props.onEnter}
							className={this.props.className}
							placeholder={this.props.placeholder}
							value={atou(this.props.value)}
							style={this.props.style}
							disabled
							/>
						</div>
					);
				}
				
			}else{
				if(this.props.onChange !== undefined){
					return (
						<div>
							<input
							type={this.props.type}
							name={this.props.name}
							id={this.props.id}
							onChange={this.handleChange}
							onKeyPress={this.props.onEnter}
							className={this.props.className}
							placeholder={this.props.placeholder}
							value={this.props.value}
							style={this.props.style}
							disabled={this.props.disabled}
							/>
						</div>
					);
				}else{
					return (
						<div>
							<input
							type={this.props.type}
							name={this.props.name}
							id={this.props.id}
							onKeyPress={this.props.onEnter}
							className={this.props.className}
							placeholder={this.props.placeholder}
							value={this.props.value}
							style={this.props.style}
							disabled
							/>
						</div>
					);

				}
			}
		}else{
			return (
				<div>
					<input
					type={this.props.type}
					name={this.props.name}
					id={this.props.id}
					onChange={this.handleChange}
					onKeyPress={this.props.onEnter}
					className={this.props.className}
					placeholder={this.props.placeholder}
					style={this.props.style}
					disabled={this.props.disabled}
					/>
				</div>
			);
		}
		
	}
}

Input.propTypes = {
	encode: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	onEnter: PropTypes.func,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.string,
	style: PropTypes.object,
	onChange: PropTypes.func
}

Input.defaultProps = {
	encode: true,
	type: 'text',
	disabled: false
}