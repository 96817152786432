import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Modal from 'react-modal';
import '../asset/style/Creation.css';
import {atou} from '../components/Encode.js';
import Input from '../components/Input.js';
import Validation from '../components/Validation.js';
import {creation_ajax} from '../components/API.js';
import $ from 'jquery';
import Loading from '../components/Loading.js';

Modal.setAppElement('#root');

const modalStyle = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundBack: 'transparent',
		padding: '0'
	},
	overlay: {
		zIndex: 2
	}
}

export default class EmailSub extends Component {
	constructor(props){
		super(props);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.emailSubmittal = this.emailSubmittal.bind(this);
		this.state = {
			name: '',
			email: '',
			loadingModal: false,
		}
	}

	handleInput(state,newValue){
		const newState = {};
		newState[state] = newValue;
		this.setState(newState);
	}

	handleCloseModal(){
		this.props.toggleModal('emailModal',false); 
	}

	emailSubmittal(){
		const document_name = document.getElementsByName('name');
		const document_name_label = document.getElementsByName('name_label');
		const document_email = document.getElementsByName('email');
		const document_email_label = document.getElementsByName('email_label');
		
		let name_validation = false;
		let email_validation = false;
		if(this.state.name.trim() !== ''){
			if(Validation('firstName',this.state.name)){
	
				document_name[0].style.borderColor='';
				document_name_label[0].style.color='';
				name_validation = true;
			}
		}
		if(this.state.email.trim() !== ''){
			if(Validation('email',this.state.email)){
				
				document_email[0].style.borderColor = '';
				document_email_label[0].style.color='';
				email_validation = true;
			}
		}
		if(!name_validation){
			document_name[0].style.borderColor='red';
			document_name_label[0].style.color='red';
		}
		if(!email_validation){
			document_email[0].style.borderColor = 'red';
			document_email_label[0].style.color='red';
		}

		if(name_validation && email_validation){
			this.handleInput('loadingModal',true);
			$.ajax({
				url:creation_ajax,
				type:"POST",
				data:{email:this.state.email.trim(), name:this.state.name.trim(), projectName:this.props.projectName, loginInfo:this.props.loginInfo}
			}).always((json_data)=>{
				const data = JSON.parse(json_data);
				this.handleInput('loadingModal',false);
				this.setState({name:'',email:''});
				this.props.resetProjectName('projectName','');
				this.handleCloseModal();
			});
		}
	}

	render(){
		const inputStyle = {borderRadius:'.25rem',width:'100%',padding:'.25rem' };
		return (
			<div>
				<Modal
				isOpen={this.props.state}
				contentLabel="Submittal E-Mail"
				onRequestClose={this.handleCloseModal}
				style={modalStyle}
				shouldCloseOnOverlayClick={false}>
					<Loading toggleModal={this.handleInput} state={this.state.loadingModal} alt="Loading Documents..."/>
					<div className='fl w-100 cf' style={{width: '50vw'}}>
						<div className='creationHeader br2 br--top f4-ns f6 ba b--dark-gray'>
							<span className='tc w-100'>Submittal E-Mail Link</span>
						</div>
						<div className='pa3 cf'>
							<div className='fl w-100 mt1' style={{wordWrap:'break-word'}}>
								<span style={{fontSize: '12px'}}><i>Please enter the recipient's name and email address.</i></span>
							</div>
							<div className='fl w-100 mt3'>
								<div className='fl w-100'>
									<div className='fl w-30-l w-100'>
										<label htmlFor='email' name='email_label'><b>E-Mail Address:</b></label>
									</div>
									<div className='fl w-70-l w-100'>
										<Input id='email' name='email' style={inputStyle} onChange={this.handleInput} placeholder='E-mail Address'/>
									</div>
								</div>
								<div className='fl w-100 mt2'>
									<div className='fl w-30-l w-100'>
										<label htmlFor='name' name='name_label'><b>Name:</b></label>
									</div>
									<div className='fl w-70-l w-100'>
										<Input id='name' name='name' style={inputStyle} onChange={this.handleInput} placeholder="Recipient's Name"/>
									</div>
								</div>
							</div>
							<div className='fl w-100 mt3'>
								<div className='fl w-100'>
									<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click grow' onClick={this.emailSubmittal}>E-Mail Submittal</button>
								</div>
								<div className='fl w-100'>
									<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click grow' onClick={this.handleCloseModal}>Close E-Mail</button>
								</div>
							</div>
						</div>
					</div>

				</Modal>
			</div>
		);
	}
}

EmailSub.propTypes = {
	toggleModal: PropTypes.func.isRequired,
	state: PropTypes.bool.isRequired,
	projectName: PropTypes.string.isRequired,
	loginInfo: PropTypes.object.isRequired,
	resetProjectName: PropTypes.func.isRequired,

}