import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../asset/style/Creation.css';
import Fade from 'react-reveal/Fade';
import $ from 'jquery';
import {creation_ajax} from '../components/API.js';

export default class CreationNav extends Component {
	constructor(props){
		super(props);
		this.state = {
			navigation: {
				'Piping Systems':{},
				'Valves and Actuation':{},
				'Sensors and Instrumentation':{},
				'QA/QC':{}
			}
		}
	}	

	componentDidMount(){
		$.ajax({
			url:creation_ajax+"?getNavigation=",
			type:"POST"
		}).done((json_data)=>{
			const data = JSON.parse(json_data);
			this.setState({navigation:data["data"]});
		});
	}

	render(){
		return (
			<div>
				<div className='creationDiv w-25 fl cf'>
					<div className='boxShadow br3 cf'>
						<div className='creationHeader br3 br--top f4-ns f6 ba b--dark-gray'>
							<span className='tc w-100'><span className='word-break'>Product</span> Navigation</span>
						</div>
						<div className="overflow-auto creationHeight" >
							<Parent parentNav={this.state.navigation["Piping Systems"]} loadChildDoc={this.props.loadChildDoc} activeChild={this.props.activeChild}/>
							<Parent parentNav={this.state.navigation["Valves and Actuation"]} loadChildDoc={this.props.loadChildDoc} activeChild={this.props.activeChild}/>
							<Parent parentNav={this.state.navigation["Sensors and Instrumentation"]} loadChildDoc={this.props.loadChildDoc} activeChild={this.props.activeChild}/>
							<Parent parentNav={this.state.navigation["QA/QC"]} loadChildDoc={this.props.loadChildDoc} activeChild={this.props.activeChild}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreationNav.propTypes = {
	loadChildDoc: PropTypes.func.isRequired,
	activeChild: PropTypes.string.isRequired
}

class Parent extends Component {
	constructor (props){
		super(props);
		this.getChildren = this.getChildren.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			showChildren: false
		}
	}

	getChildren(childString){
		let newChildrens = [];
		let childrens = childString.split('|').filter((data)=>{return data!=null;});
		childrens.forEach((data)=>{
			if(data.indexOf(';')!==-1){
				let stripedLabel;
				stripedLabel = data.substr(data.indexOf(';')+1);
				if(stripedLabel.substring(0,12)==='Submittal - '){
					stripedLabel = stripedLabel.substr(12);
				}
				let stripedPartition;
				stripedPartition = data.slice(0,data.indexOf(';'));
				const stripedData = [stripedLabel,stripedPartition];
				
				newChildrens.push(stripedData);
			}
		});
		return newChildrens;
	}

	handleClick(e){
		this.setState({showChildren: !this.state.showChildren});
	}

	render(){
		let childrenString = this.props.parentNav["Children"];
		if(childrenString !== undefined){
			const children = this.getChildren(childrenString);
			const icon = this.state.showChildren? '↑' : '↓';
			return(
				<div>
					<Fade>
						
						<div className="creationHeader ba shadow-click">
							<div className='pl3 w-100 pv3'>
								<div onClick={this.handleClick}>
									<div className='fl w-90'>
										<span>{this.props.parentNav["Label"]}</span>
									</div>
									<div className='fl w-10'>
										<span>{icon}</span>
									</div>
								</div>
							</div>
						</div>
		
						<div>
							<Children childrenNav={children} parent={this.props.parentNav["Label"]} loadChildDoc={this.props.loadChildDoc} toggleShow={this.state.showChildren} activeChild={this.props.activeChild}/>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

Parent.propTypes = {
	parentNav: PropTypes.object.isRequired,
	loadChildDoc: PropTypes.func.isRequired,
	activeChild: PropTypes.string.isRequired

}

class Children extends Component {
	constructor(props){
		super(props);

	}
	render(){
		if(this.props.toggleShow){
			const child = this.props.childrenNav.map((data,i)=> {
				let active = ' bg-black-20 black';
				if(this.props.activeChild==data[1]){
					active = ' bg-black-60 white';
				}
				return (<div id={data[1]} href={this.props.parent} className={'creationHeader-nobackground bb pl4 shadow-click ' + active} onClick={this.props.loadChildDoc} key={i}>
					{data[0]}
				</div>);
			});
			return (
				<div>
					<Fade>
						<div>
							<span>{child}</span>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

Children.propTypes = {
	childrenNav: PropTypes.array.isRequired,
	toggleShow: PropTypes.bool.isRequired,
	loadChildDoc: PropTypes.func.isRequired,
	activeChild: PropTypes.string.isRequired,
	parent: PropTypes.string.isRequired
}