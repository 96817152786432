import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import CreationNav from '../components/CreationNav.js';
import CreationDoc from '../components/CreationDoc.js';
import CreationToc from '../components/CreationToc.js';
import Preview from '../components/Preview.js';
import Upload from '../components/Upload.js';
import Reorder from '../components/Reorder.js';
import EditCover from '../components/EditCover.js';
import ProjectInfo from '../components/ProjectInfo.js';
import $ from 'jquery';
import {creation_ajax} from '../components/API.js';

export default class CreationHome extends Component {
	constructor(props){
		super(props);
		this.previewDoc = this.previewDoc.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.addDoc = this.addDoc.bind(this);
		this.removeDoc = this.removeDoc.bind(this);
		this.uploadDoc = this.uploadDoc.bind(this);
		this.addUserDoc = this.addUserDoc.bind(this);
		this.previewCover = this.previewCover.bind(this);
		this.editCover = this.editCover.bind(this);
		this.handleProjectInfo = this.handleProjectInfo.bind(this);
		this.state = {
			previewDoc: '',
			previewModal: false,
			uploadModal: false,
			reorderModal: false,
			editCoverModal: false,
			projectInfoModal: false,
			currentDocSelection: ''
		}
	}

	

	previewDoc(e){
	
		let coloredRow = e.currentTarget.attributes.rowid.value;
		var x = document.getElementsByName(coloredRow);
		x[0].classList.remove('bg-black-20');
		x[0].classList.add('bg-black-60');
		x[1].classList.add('white');

		if(this.state.currentDocSelection !== '' && this.state.currentDocSelection !== coloredRow){
			var y = document.getElementsByName(this.state.currentDocSelection);
			y[0].classList.remove('bg-black-60');
			y[0].classList.add('bg-black-20');
			y[1].classList.remove('white');
		}
		let docTable = 'resource';
		const fileName = e.currentTarget.id;
		$.ajax({
			url:creation_ajax,
			type:"POST",
			data:{"tableinfo":true}
		}).done((data)=>{
			try{
				const tableinfo = JSON.parse(data);
				docTable = tableinfo.data.doctable;
			}catch(err){
				docTable = 'resource';
			}
		}).always(()=>{
			let docURI = "https://gfpsresourcecenter.blob.core.windows.net/"+docTable+"/"+fileName+"&embedded=true";
			console.log(docURI);
			this.setState({previewDoc: docURI, previewModal: true, currentDocSelection: coloredRow});
		});
		
		
		
	}

	previewCover(e){
		let docURI = "https://webtools.blob.core.windows.net/submittal-coverpage/"+this.props.coverPage+"&embedded=true";
		this.setState({ previewDoc: docURI, previewModal: true});
	}

	uploadDoc(e){
		this.setState({uploadModal: true});
	}

	handleInput(state,newValue){
		const newState = {};
		newState[state]=newValue;
		this.setState(newState);
	}
	addUserDoc(filenames){
		const parent = "User Documents";
		const toc = this.props.loadTOC;
		const currentDoc = toc[parent];
		
		const pageRange = this.props.loadPageRange;
		const currentRange = pageRange[parent];
		for(var i = 0; i<filenames.length; i++){
			currentRange.push('');
			currentDoc.push(filenames[i]);
		}
		toc[parent]=currentDoc;
		pageRange[parent]=currentRange;
		this.props.updateDoc(toc,pageRange);
	}
	addDoc(e){
		const doc = e.target.id;
		if(doc !==''){
			const parentContainer = e.currentTarget.attributes.href.value;
			const toc = this.props.loadTOC;
			const pageRange = this.props.loadPageRange;
			for(var key in toc){
				if(key === parentContainer){
					if(parentContainer=='QA/QC'){
						const mainQAQC = 'Quality Manual for Installation of GF Piping Systems Products.pdf';
						if(toc[key].indexOf(mainQAQC)==-1){
							toc[key].unshift(mainQAQC);
							pageRange[key].unshift('');
						}
					}
					if(toc[key].indexOf(doc)==-1){
						toc[key].push(doc);
						pageRange[key].push('');
					}
				}
			}
			this.props.updateDoc(toc,pageRange);
		}
		this.setState({currentDocSelection: ''});
	}
	removeDoc(e){
		const doc = e.currentTarget.id;
		const parent = e.currentTarget.attributes.href.value;
		const toc = this.props.loadTOC;
		const pageRange = this.props.loadPageRange;
		for(var key in toc){
			if(key === parent){
				if(parent === 'QA/QC' && doc === 'Quality Manual for Installation of GF Piping Systems Products.pdf' && toc[key].length >1){
					break;
				}
				const index = toc[key].indexOf(doc);
				if(index!==-1){
					toc[key].splice(index,1);
					pageRange[key].splice(index,1);
				}
			}
		}
		
		this.props.updateDoc(toc,pageRange);
	}

	editCover(e){

		this.setState({'editCoverModal':true});
	}

	handleProjectInfo(projectInfo){
		$.ajax({
			url:creation_ajax,
			type:"POST",
			data:{"projectInfo":projectInfo,"token":this.props.loginInfo.token},
		}).done((json_data)=>{
			const data = JSON.parse(json_data);
			if(data['data']){
				this.setState({projectInfoModal: false, reorderModal: true});
			}else{
				console.log('fail');
			}
		}).fail((error)=>{
			console.log(error);
		})
	}

	render(){
		if(this.props.pageActive.CreationHome){
			
			return (
				<div>
					<Fade>
						<div className="fl cf w-100 h-100 pa4-ns pa2">
							<EditCover state={this.state.editCoverModal} toggleModal={this.handleInput} projectName={this.props.projectName} updateProjectName={this.props.handleInput} loginInfo={this.props.loginInfo} />
							<Preview state={this.state.previewModal} toggleModal={this.handleInput} pdfURI={this.state.previewDoc}/>
							<Upload toggleModal={this.handleInput} state={this.state.uploadModal} loginInfo={this.props.loginInfo} projectName={this.props.projectName} handleUserDoc={this.addUserDoc}/>
							<ProjectInfo toggleModal={this.handleInput} state={this.state.projectInfoModal} loginInfo={this.props.loginInfo} projectName={this.props.projectName} submit={this.handleProjectInfo}/>
							<Reorder toggleModal={this.handleInput} state={this.state.reorderModal} toc={this.props.loadTOC} pageRange={this.props.loadPageRange} tocOrder={this.props.order} reorderHeader={this.props.reorderHeader} reorderDocument={this.props.reorderDocument} updatePageRange={this.props.updatePageRange} coverPage={this.props.coverPage} loginInfo={this.props.loginInfo} projectName={this.props.projectName} navigation={this.props.navigation} pageActive={this.props.pageActive} updateLogin={this.props.updateLogin} />
							<CreationNav loadChildDoc={this.props.loadChildDoc} activeChild={this.props.childDoc}/>
							<CreationDoc activeChild={this.props.childDoc} previewDoc={this.previewDoc} addDoc={this.addDoc} parentDoc={this.props.parentDoc} toc={this.props.loadTOC}/>
							<CreationToc toc={this.props.loadTOC} removeDoc={this.removeDoc} uploadDoc={this.uploadDoc} tocOrder={this.props.order} handleReorder={this.handleInput} previewCover={this.previewCover} coverPage={this.props.coverPage} editCover={this.editCover}/>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

CreationHome.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired,
	projectName: PropTypes.string.isRequired,
	loginInfo: PropTypes.object.isRequired,
	loadTOC: PropTypes.object.isRequired,
	loadPageRange: PropTypes.object.isRequired,
	order: PropTypes.array.isRequired,
	updateDoc: PropTypes.func.isRequired,
	parentDoc: PropTypes.string.isRequired,
	childDoc: PropTypes.string.isRequired,
	loadChildDoc: PropTypes.func.isRequired,
	reorderHeader: PropTypes.func.isRequired,
	reorderDocument: PropTypes.func.isRequired,
	updatePageRange: PropTypes.func.isRequired,
	coverPage: PropTypes.string.isRequired,
	updateLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired

}


