import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import loader from './loader.gif';

Modal.setAppElement('#root');

const modalStyles = {
	content:{
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
	    border : 'hidden',
	    background: 'transparent',
	    opacity: '1',
	},
	overlay:{
		zIndex: 2
	}
}

export default class Loading extends Component {
	constructor(props){
		super(props);
		this.handleCloseModal = this.handleCloseModal.bind(this);
	}

	handleCloseModal(){
		this.props.toggleModal('loadingModal',false);
	}

	render(){
		return (
			<div>
				<Modal
				isOpen={this.props.state}
				contentLabel="Missing Required Fields"
				onRequestClose={this.handleCloseModal}
				shouldCloseOnOverlayClick={false}
				style={modalStyles}>
					<div className='tc w-100'>
						<img src={loader} alt={this.props.alt}/>
					</div>
				</Modal>
			</div>
			);
	}
}

Loading.propTypes = {
	state: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired,
	alt: PropTypes.string
};

Loading.defaultProps = {
	alt: 'Loading...'
}