import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import {atou} from '../components/Encode.js';
import TopNav from './TopNav.js';
import Option from './Option.js';
import MySubmittal from './MySubmittal.js';
import CreateSubmittal from './CreateSubmittal.js';
import UploadCover from './UploadCover.js';
import BuildCover from './BuildCover.js';
import CreationHome from './CreationHome.js';

export default class Submittal extends Component {
	constructor(props){
		super(props);
		this._isEmpty = this._isEmpty.bind(this);
		this.handleActivePage = this.handleActivePage.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
		this.navigation = this.navigation.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleTOC = this.handleTOC.bind(this);
		this.updateDoc = this.updateDoc.bind(this);
		this.loadChildDoc = this.loadChildDoc.bind(this);
		this.reorderHeader = this.reorderHeader.bind(this);
		this.reorderDocument = this.reorderDocument.bind(this);
		this.updatePageRange = this.updatePageRange.bind(this);
		this.updateProjectData = this.updateProjectData.bind(this);


		this.state = {
			activeColor: 'azure',
			activePage: 'MySubmittal',
			Pages: {
				Option: false,
				MySubmittal: true,
				CreateSubmittal: false,
				BuildCover: false,
				UploadCover: false,
				CreationHome: false
			},
			projectname: "",
			coverpage: "",
			childDoc: '',
			parentDoc: '',
			submittalTOC: {
				'Piping Systems':[],
				'Valves and Actuation':[],
				'Sensors and Instrumentation':[],
				'User Documents':[],
				'QA/QC':[]
			},
			pageRange: {
				'Piping Systems':[],
				'Valves and Actuation':[],
				'Sensors and Instrumentation':[],
				'User Documents':[],
				'QA/QC':[]
			},
			order: ["Piping Systems", "Valves and Actuation", "Sensors and Instrumentation", "User Documents","QA/QC"]

		};
	}

	_isEmpty(obj){
		for(var key in obj){
			if(obj.hasOwnProperty(key)){
				return false;
			}
		}
		return true;
	}

	handleLogOut(e){
		window.location.reload(true); 
	}

	handleActivePage(page,color){
		this.setState({activePage:page, activeColor:color});
	}

	navigation(navState, navAway, navTo, color=false){
		const newState = {};
	    let updatedNavState = navState;
	    updatedNavState[navAway] = false;
	    updatedNavState[navTo] = true;
	    newState['Pages'] = updatedNavState;
	    newState['activePage'] = navTo;
	    if(color){
	    	newState['activeColor'] = color;
	    }
	    this.setState(newState);
	}

	handleInput(state,newValue){
		const newState = {};
		newState[state] = newValue;
		this.setState(newState);
	}

	handleTOC(projectName,loadTOC){
		const newState = {};
		newState['projectname'] = projectName;

		const toc = {};
		const pageRange = {};
		const newOrder = [];
		for(var i=0; i<loadTOC.length; i++){
			const parent = Object.keys(loadTOC[i])[0];
			if(this.state.order.indexOf(parent)!== -1){
				newOrder.push(parent);
				const documents = [];
				const ranges = [];
				
				for(var k=0; k<loadTOC[i][parent].length; k++){

					documents.push(loadTOC[i][parent][k].document_name);
					ranges.push(loadTOC[i][parent][k].page_range == null ? '' : loadTOC[i][parent][k].page_range);
				}
				toc[parent] = documents;
				pageRange[parent] = ranges;
			}else{
				newState['coverpage'] = loadTOC[i][parent];

			}
		}
	
		Object.keys(this.state.pageRange).forEach(rangeKey =>{
			if(!pageRange.hasOwnProperty(rangeKey)){
				pageRange[rangeKey]=this.state.pageRange[rangeKey];
			}
		});
		this.state.order.forEach(orderKey=>{
			if(newOrder.indexOf(orderKey)==-1){
				newOrder.push(orderKey);
			}
		});
		Object.keys(this.state.submittalTOC).forEach(tocKey=>{
			if(!toc.hasOwnProperty(tocKey)){
				toc[tocKey]=this.state.submittalTOC[tocKey];
			}
		});

		newState['pageRange'] = pageRange;
		newState['order'] = newOrder;
		newState['submittalTOC'] = toc;
		newState['childDoc'] = "";
		newState['parentDoc'] = "";
		this.setState(newState);
	}

	updateDoc(toc,pageRange){
		const newState = {};
		newState['submittalTOC'] = toc;
		newState['pageRange'] = pageRange;
		this.setState(newState);
	}

	loadChildDoc(e){
		this.setState({childDoc: e.target.id, parentDoc: e.target.attributes.href.value});
	}

	reorderHeader(e){
		const direction = e.currentTarget.attributes.direction.value;
		const header = e.currentTarget.attributes.header.value;
		const newOrder = this.state.order;

		const headerPosition = newOrder.indexOf(header);
		if(direction=='up'){
			if(headerPosition !== 0){
				let newPosition = newOrder.indexOf(header);
			
				while(this.state.submittalTOC[newOrder[newPosition-1]].length==0 && newPosition-2 >= 0){
					newPosition -= 1;

				}
				newOrder.splice(headerPosition,1);
				newOrder.splice(newPosition-1,0,header);
			}
		}else if(direction=='down'){
			if(headerPosition !== newOrder.length-1 && this.state.submittalTOC[newOrder[headerPosition+1]].length !== 0){
				let newPosition = newOrder.indexOf(header);
				while(this.state.submittalTOC[newOrder[newPosition+1]].length==0){
					newPosition += 1;
				}
				newOrder.splice(headerPosition,1);
				newOrder.splice(newPosition+1,0,header);
			}
		}
		const newState = {};
		newState['order'] = newOrder;
		this.setState(newState);
	}

	reorderDocument(e){
		const direction = e.currentTarget.attributes.direction.value;
		const doc = e.currentTarget.attributes.document.value;
		const parent = e.currentTarget.attributes.parent.value; 
		const submittalTOC = this.state.submittalTOC;
		const newOrder = this.state.submittalTOC[parent];
		const pageRange = this.state.pageRange;
		const newRange = this.state.pageRange[parent];
		
		const documentPosition = newOrder.indexOf(doc);
		const range = this.state.pageRange[parent][documentPosition];
		const qaqcDoc = 'Quality Manual for Installation of GF Piping Systems Products.pdf';
		
		if(direction=='up'){
			if((documentPosition !==0 && parent!=='QA/QC' ) || (documentPosition!==1 && documentPosition!==0 && newOrder.indexOf(qaqcDoc)==0 && parent =='QA/QC')){
				newOrder.splice(documentPosition,1);
				newOrder.splice(documentPosition-1,0,doc);
				newRange.splice(documentPosition,1);
				newRange.splice(documentPosition-1,0,range);
			}
		}else if(direction=='down'){
			if(documentPosition !== newOrder.length-1 && doc !== qaqcDoc){
				newOrder.splice(documentPosition,1);
				newOrder.splice(documentPosition+1,0,doc);
				newRange.splice(documentPosition,1);
				newRange.splice(documentPosition+1,0,range);
			}
		}
		const newState = {};
		submittalTOC[parent] = newOrder;
		pageRange[parent] = newRange;
		newState['submittalTOC'] = submittalTOC;
		newState['pageRange'] = pageRange;
		this.setState(newState);
	}

	updatePageRange(parent_index,value){
		const parent = parent_index.split("_")[0];
		const index = parent_index.split("_")[1];
		const newState = {};
		const newPageRange = this.state.pageRange;
		newPageRange[parent][index]=value;
		newState['pageRange']=newPageRange;
		this.setState(newState);
	}

	updateProjectData(newState_array){
		this.setState(newState_array);
	}



	render(){
		if(this.props.pageActive.Submittal){
			const name2 = atou(this.props.login.firstName) + " " + atou(this.props.login.lastName);
			return (
				<div>
					
					<div>
						<div className='fl w-100' >
							<TopNav name={name2} onClick={this.handleActivePage} activeColor={this.state.activeColor} activePage={this.state.activePage} logOut={this.handleLogOut} pageActive={this.state.Pages} navigation={this.navigation} updateProjectName={this.handleInput} />
						</div>
						<div className='fl w-100'>
							<Option pageActive={this.state.Pages} navigation={this.navigation} />
							<MySubmittal pageActive={this.state.Pages} navigation={this.navigation} loginInfo={this.props.login} updateTOC={this.handleTOC} updateLogin={this.props.updateLogin} projectName={this.state.projectname}/>
							<CreateSubmittal pageActive={this.state.Pages} navigation={this.navigation}  updateProjectName={this.handleInput} projectName={this.state.projectname} />
							<UploadCover pageActive={this.state.Pages} navigation={this.navigation} updateProjectName={this.handleInput} projectName={this.state.projectname} loginInfo={this.props.login} updateLogin={this.props.updateLogin}updateProjectData={this.updateProjectData} />
							<BuildCover pageActive={this.state.Pages} navigation={this.navigation} updateProjectName={this.handleInput} projectName={this.state.projectname} loginInfo={this.props.login} updateLogin={this.props.updateLogin} updateProjectData={this.updateProjectData} />
							<CreationHome pageActive={this.state.Pages} navigation={this.navigation} projectName={this.state.projectname} loginInfo={this.props.login} loadTOC={this.state.submittalTOC} loadPageRange={this.state.pageRange} order={this.state.order} updateDoc={this.updateDoc} childDoc={this.state.childDoc} parentDoc={this.state.parentDoc} loadChildDoc={this.loadChildDoc} reorderHeader={this.reorderHeader} reorderDocument={this.reorderDocument} updatePageRange={this.updatePageRange} coverPage={this.state.coverpage} updateLogin={this.props.updateLogin} handleInput={this.handleInput} />
						</div>
					</div>
			
				</div>
			);
		}else{
			return null;
		}
	}

}
Submittal.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired,
	updateLogin: PropTypes.func.isRequired,
	login: PropTypes.object
}