import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Modal from 'react-modal';
import '../asset/style/Option.css';

Modal.setAppElement('#root');

const modalStyles = {
	content:{
		top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    transform: 'translate(-50%, -50%)',
	    backgroundBack: 'transparent',
	    borderRadius: '.5rem',
	    padding: '0px',
	    width: '50vw'
	 
	},
	overlay: {
		zIndex: 2
	}
}

export default class Preview extends Component {
	constructor(props){
		super(props);
		this.handleCloseModal = this.handleCloseModal.bind(this);
	}

	handleCloseModal(){
		this.props.toggleModal('previewModal',false);
		this.props.toggleModal('previewDoc','');
	}
	render(){
		return (
			<div>
				<Modal
				isOpen={this.props.state}
				contentLabel="Preview Documents"
				onRequestClose={this.handleCloseModal}
				style={modalStyles}
				shouldCloseOnOverlayClick={false}>
					<div className='fl w-100 cf'>
						<div className='fl w-100 tc'>
							<div className='fl w-100 optionHeader br3 br--top f5 ba b--dark-gray'>
								<p className='tc w-100' >Preview</p>
							</div>
							<iframe className='mv2' src={'https://docs.google.com/viewerng/viewer?url='+this.props.pdfURI} style={{height:'65vh', width:'45vw'}}/>
						</div>
						<div className='fl w-100'>
							<button className='bg-bottom f5 link dim br3 ph3 pv2 white w-100' onClick={this.handleCloseModal} style={{ backgroundColor : '#1965A3' }}>Close Preview</button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

Preview.propTypes = {
	state: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired,
	pdfURI: PropTypes.string.isRequired
};