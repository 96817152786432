import {atou} from './Encode.js';

export default function Validation(name,value,encode=true){
	const regex = {
		'firstName' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@]{2,}$/g,
		'lastName' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@]{2,}$/g,
		'email' : /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]){0,}@[A-Za-z0-9-]+\.([A-Za-z]{1,6}\.)?[a-zA-Z]{2,6}$/g,
		'uname' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9~!@#$%^&*-_+=?.]{2,}$/g,
		'pword' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9~!@#$%^&*-_+=?.]{2,}$/g,
		'answer1' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 ']{2,}$/g,
		'answer2' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 ']{2,}$/g,
		'projectname' : /^(?!\s)(?!.*\s$)(?=[a-zA-Z0-9]*)[a-zA-Z0-9 ')(}{~!@#$^\-_+=]{2,}$/g,
		'projectcontact1' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@]{2,}$/g,
		'projectcontactcompany' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@]{2,}$/g,
		'projectcontactemail' : /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]){0,}@[A-Za-z0-9-]+\.([A-Za-z]{1,6}\.)?[a-zA-Z]{2,6}$/g,
		'projectcontactphone' : /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g,
		'pageRange' : /^(([ 0-9]|\-[0-9]+)+(?!\s;$)(;?)){1,}$/g,
	};
	if(name in regex){
		if(encode){
			return regex[name].test(atou(value));
		}else{
			return regex[name].test(value);
		}
	}else{
		return true;
	}
}