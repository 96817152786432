export function utoa(str){
	return btoa(btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    })));
 	// return btoa(btoa(encodeURIComponent(str)));
}
export function atou(str){
	// return atob(decodeURIComponent(atob(str).split('').map(function(c) {
    //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    // }).join('')));
 	return atob(decodeURIComponent(atob(str)));
}