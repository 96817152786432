import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import '../asset/style/Creation.css';
import '../asset/style/Option.css';
import {atou ,utoa} from '../components/Encode.js';
import {creation_ajax} from '../components/API.js';
import {mysubmittal_ajax} from '../components/API.js';
import $ from 'jquery';
import download_icon from '../asset/img/download.png';
import email_icon from '../asset/img/email.png';
import edit_icon from '../asset/img/edit.png';
import delete_icon from '../asset/img/delete.png';
import DownloadSub from '../components/DownloadSub.js';
import downloadPDF from '../components/DownloadPDF.js';
import EmailSub from '../components/EmailSub.js';
import Loading from '../components/Loading.js';

export default class MySubmittal extends Component {
	constructor(props){
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.state = {
			submittalInfo: null,
			downloadModal: false,
			emailModal: false,
			downloadLink: '',
			projectName: '',
			loadingModal: false,
		}

	}

	componentDidMount(){
		$.ajax({
			url:creation_ajax+"?mysubmittal="+this.props.loginInfo.token,
			type:"POST"
		}).done((json_data)=>{
			const data = JSON.parse(json_data);
			this.setState({'submittalInfo':data["data"]});
		});
	}

	componentDidUpdate(prevProps,prevState){

		if(this.props.loginInfo !== prevProps.loginInfo || this.props.projectName !== prevProps.projectName){

			$.ajax({
				url:creation_ajax+"?mysubmittal="+this.props.loginInfo.token,
				type:"POST"
			}).done((json_data)=>{
				const data = JSON.parse(json_data);
				this.setState({'submittalInfo':data["data"]});
			});
		}
	}


	handleInput(state,newValue){
		const newState = {};
		newState[state]=newValue;
		this.setState(newState);
	}

	handleClick(e){
		const action = e.target.attributes.alt.value;
		const submittalID = e.target.id;
		const projectName = e.target.attributes.projectname.value;
		switch(action){
			case "download":
				const submittalPackage_Link = "https://webtools.blob.core.windows.net/submittal-packages/"+atou(this.props.loginInfo['token'])+' - '+projectName;

				downloadPDF(submittalPackage_Link);

				this.setState({downloadLink: submittalPackage_Link, downloadModal: true});

				break;
			case "email":
				this.setState({emailModal: true, projectName:projectName});
				break;
			case "edit":
				this.handleInput('loadingModal',true);
				$.ajax({
					url:mysubmittal_ajax+"?edit="+submittalID,
					type:"POST"
				}).done((json_data)=>{
					const data = JSON.parse(json_data);
					this.handleInput('loadingModal',false);
					this.props.updateTOC(utoa(projectName),data["data"]);
					this.props.navigation(this.props.pageActive, 'MySubmittal', 'CreationHome');

				}).always(()=>{
					this.handleInput('loadingModal',false);
				});
				break;
			case 'delete':
				this.handleInput('loadingModal',true);
				const coverPage = e.target.attributes.coverpage.value;
				const data = {
					sid: submittalID,
					projectName: projectName,
					coverPage: coverPage
				};
				$.ajax({
					url:mysubmittal_ajax,
					type:'POST',
					data:{delete_data:data,loginInfo:this.props.loginInfo}
				}).always((json_data)=>{
					const data = JSON.parse(json_data);
					this.handleInput('loadingModal',false);
					if(data["status"]=='success'){
						this.props.updateLogin(data['loginInfo']);
					}
				});
				break;
		}
	}

	render(){

		if(this.props.pageActive.MySubmittal){
			
			const row = "pv3 pr3 bb b--black-20";
			let table;
			if(this.state.submittalInfo !== null){
				table = this.state.submittalInfo.map((data,i)=>{
					let completeSubmittal;
					let completeSubmittal2;
					if(data['toc_row']==1){
						completeSubmittal = <td colSpan='2' className={'red f4 '+row}>Submittal Package is not complete</td>
					}else{
						completeSubmittal = <td className={row} ><img className="h2 shadow-click grow-large w2" src={download_icon} alt="download" projectname={data['project_name']} onClick={this.handleClick} id={data['SID']}/></td>;
						
						completeSubmittal2 = <td className={row} ><img className="h2 shadow-click grow-large w2" src={email_icon} alt="email" projectname={data['project_name']} onClick={this.handleClick} id={data['SID']}/></td>;
					}
					
					return (
						<tr key={i}>
							<td className={row} >{data['row_count']}</td>
							<td className={row} >{data['project_name']}</td>
							{completeSubmittal}
							{completeSubmittal2}
							<td className={row} ><img className="h2 w2 shadow-click grow-large" src={edit_icon} alt="edit" projectname={data['project_name']} onClick={this.handleClick} id={data['SID']} /></td>
							<td className={row} ><img className="h2 w2 shadow-click grow-large" src={delete_icon} alt="delete" projectname={data['project_name']} coverpage={data['cover_page_name']} onClick={this.handleClick} id={data['SID']}/></td>
						</tr>
					);
				});
			}
			return (
				<div className='zIndex-2'>
					<Fade>
						<Loading toggleModal={this.handleInput} state={this.state.loadingModal} alt="Loading Documents..."/>
						<div className='w-100 pa3'>
							<DownloadSub toggleModal={this.handleInput} state={this.state.downloadModal} downloadLink={this.state.downloadLink}/>
							<EmailSub toggleModal={this.handleInput} state={this.state.emailModal} projectName={this.state.projectName} loginInfo={this.props.loginInfo} resetProjectName={this.handleInput}/>
							<div className='fullsizeDiv cf br3 pa3'>
								<h3 className='mv1'>Hello {atou(this.props.loginInfo.firstName)+" "+atou(this.props.loginInfo.lastName)}, below are your Submittal Packages.</h3>
								<h4 className='mv0'><i>Submittal Count ({atou(this.props.loginInfo.project_count) +"/"+ atou(this.props.loginInfo.project_cap)})</i></h4>
								<hr/>
								<div className='fl w-100 ba br3 insetBoxShadow pa2 overflow-auto' style={{height:'70vh'}}>
									<table className='f6 w-100 center tc' cellSpacing='0'>
										<thead>
											<tr>
												<th className='bb'>#</th>
												<th className='bb bl br'><b>Project Name</b></th>
												<th className='bb'><b>Download</b></th>
												<th className='bb bl br'><b>E-mail</b></th>
												<th className='bb'><b>Edit</b></th>
												<th className='bb bl'><b>Delete</b></th>
											</tr>
										</thead>
										<tbody>
											{table}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

MySubmittal.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired,
	loginInfo: PropTypes.object.isRequired,
	updateTOC: PropTypes.func.isRequired,
	updateLogin: PropTypes.func.isRequired,
	projectName: PropTypes.string.isRequired
}