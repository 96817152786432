import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../asset/style/Header.css';
import GF from '../asset/img/Georg_Fischer.png';
import Title from '../asset/img/submittal_title.png';

export default class TopNav extends Component {
	constructor(props){
		super(props);
		this.divClick = this.divClick.bind(this);
		this.mouseDown = this.mouseDown.bind(this);
		this.state = {
			button: {
				Option: 'black',
				MySubmittal: 'black',
				logout: 'white',
			},
			navigation: {
				Option: 'remove_shadow',
				MySubmittal: 'box_shadow',
				logout: 'box_shadow'
			}
			
		}
	}

	static getDerivedStateFromProps(props,state){
		const newState = {};
		const currentState = state;
		if(props.activePage in currentState.navigation){
			for (const page in currentState.navigation){
				if(props.activePage == page ){
					newState[page] = 'remove_shadow';
				}else{					
					newState[page] = 'box_shadow';
				}
			}
			currentState['navigation'] = newState;
		}
		
		return currentState;
		
	}

	divClick(e){

		const newState = {};
		const navigation = this.state.navigation;

		for(const page in navigation){

			newState[page] = e.currentTarget.id == page ? 'remove_shadow' : 'box_shadow';
		}

		this.setState({navigation: newState});

		this.props.onClick(e.currentTarget.id,e.currentTarget.style.backgroundColor);
		this.props.navigation(this.props.pageActive, this.props.activePage, e.currentTarget.id,e.currentTarget.style.backgroundColor);
		this.props.updateProjectName('projectname','');
	}


	mouseDown(e){
		const newState = {};
		const button = this.state.button;
		const id = e.currentTarget.id;
		for(const page in button){
			if(id == page){
				newState[page] = button[page] == 'white' ? 'black' : 'white';
			}else{
				newState[page] = this.state.button[page];
			}
		}

		this.setState({button: newState});
	}

	render(){
		return (
			<div>
				<div className="header fl w-100" style={{backgroundColor: this.props.activeColor}}>
					<div className='fl w-100' style={{paddingTop:'35px', paddingLeft:'35px', paddingRight:'35px', zIndex:-1}}>
						<div className="fl w-50 tl">
							<img src={Title} alt="GF Logo" width="280" height="65" />
						</div>
						<div className="fl w-50 tr">
							<img src={GF} alt="GF Logo" width="100" height="35" />
						</div>
					</div>
					<div className='fl w-100 mt1 f6 bn'>
						<div className="fl welcome_header blue-header-color w-third box_shadow pb1 pt2 ph3">
							<span>Welcome, {this.props.name}!</span>
						</div>
						<div className='fl w-two-thirds' style={{zIndex:-1}}>
							<div id='Option' style={{backgroundColor: 'azure'}}  className={"fl tc nav_header shadow-click w-third pb1 tc pt2 ph4-ns ph2 " + this.state.button.Option + " " + this.state.navigation.Option} onClick={this.divClick} onMouseDown={this.mouseDown} onMouseUp={this.mouseDown} >
								<span>Home</span>
							</div>
							<div id='MySubmittal' style={{backgroundColor:'azure'}}  className={"fl tc nav_header shadow-click w-third pb1 tc pt2 ph4-ns ph2 " + this.state.button.MySubmittal + " " + this.state.navigation.MySubmittal} onClick={this.divClick} onMouseDown={this.mouseDown} onMouseUp={this.mouseDown} >
								<span >My Submittal</span>
							</div>
							<div id='logout' className={"fl blue-header-color tc nav_header shadow-hover w-third pb1 tc pt2 ph4-ns ph2 logout_header " + this.state.button.logout + " " + this.state.navigation.logout} onClick={this.props.logOut} onMouseDown={this.mouseDown} onMouseUp={this.mouseDown} >
								<span >Logout</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

TopNav.propTypes = {
	name: PropTypes.string.isRequired,
	activeColor: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	activePage: PropTypes.string.isRequired,
	logOut: PropTypes.func.isRequired,
	updateProjectName: PropTypes.func.isRequired
}

//this component changes color wfrom a prop coming from submittal
//the submittal get the populates the prop from the state which is set from this component, which receives a function to save to parent state