import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import '../asset/style/Option.css';
import Input from '../components/Input.js';
import Validation from '../components/Validation.js';
import buildSubmittal from '../asset/img/Build-submittal.jpg';
import uploadSubmittal from '../asset/img/Upload-submittal.jpg';

export default class CreateSubmittal extends Component {
	constructor(props){
		super(props);
		this.handleInput = this.handleInput.bind(this);
		this.buildCover = this.buildCover.bind(this);
		this.uploadCover = this.uploadCover.bind(this);
		this._checkProjectName = this._checkProjectName.bind(this);

	}


	handleInput(state,newValue){

		this.props.updateProjectName(state,newValue);
		const label_id = state+"_label";
		var x = document.getElementsByName(state);
		var y = document.getElementsByName(label_id);

		if(Validation(state,newValue)){
			x[0].style.borderColor = "";  	
			y[0].style.color = "";
		}else{
			x[0].style.borderColor = "red";  	
			y[0].style.color = "red";
		}
	}

	buildCover(e){
		if(this._checkProjectName()){
			this.props.navigation(this.props.pageActive, 'CreateSubmittal', 'BuildCover');
		}
	}

	uploadCover(e){
		if(this._checkProjectName()){
			this.props.navigation(this.props.pageActive, 'CreateSubmittal', 'UploadCover');
		}
	}

	_checkProjectName(){
		if(this.props.projectName){
			if(Validation('projectname',this.props.projectName)){
				return true;
			}
		}
		return false;
	}

	render(){
		if(this.props.pageActive.CreateSubmittal){
			return (
				<div className='zIndex-2'>
					<Fade>
						<div className='optionDiv w-auto br3 cf' >
							<div className='fl w-100 optionHeader br3 br--top f3 ba b--dark-gray'>
								<p className='tc w-100' >Cover Sheet</p>
							</div>

							<div className='fl w-100 pa4 pt2'>
								<p className='pa0'><i>Please enter a Project Name and choose one of the following options.</i></p>
								<div className='fl w-100 f5'>
									<label htmlFor="projectname" name='projectname_label' ><b>Project Name<span className='red'>*</span>:</b></label>
								</div>
								<div className='fl w-100'>
									<Input id='projectname' name='projectname' className='br2 w-100 pa1' onChange={this.handleInput} value={this.props.projectName} />
								</div>
								<br/>
								<div className='w-100' style={{marginTop:'3rem'}}>
									<div className='fl w-50-ns w-100 tr pr4-ns pr3-m'>
										<img className='optionImg shadow-click grow' src={buildSubmittal} alt="Build-Cover-Page" onClick={this.buildCover}/>
									</div>
									<div className='fl w-50-ns w-100 tl pl4-ns pl3-m'>
										<img className='optionImg shadow-click grow' src={uploadSubmittal} alt="Upload-Cover-Page" onClick={this.uploadCover}/>
									</div>
								</div>
							</div>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

CreateSubmittal.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired,
	updateProjectName: PropTypes.func.isRequired,
	projectName: PropTypes.string.isRequired
}