import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../asset/style/Creation.css';
import Fade from 'react-reveal/Fade';
import Minus from '../asset/img/minus_icon.png';
import Preview from '../asset/img/preview_icon.png';
import edit_icon from '../asset/img/edit.png';


export default class CreationToc extends Component {
	constructor(props){
		super(props);
		this.openProjectInfoModal = this.openProjectInfoModal.bind(this);
		
	}	

	openProjectInfoModal(e){
		this.props.handleReorder('projectInfoModal',true);
	}

	render(){
		const TOC = this.props.tocOrder.map((data,i)=>{
			return <Parent key={i} parent={data} parentNav={this.props.toc[data]} removeDoc={this.props.removeDoc}/>;
		});

		return (
			<div>
				<div className='creationDiv w-25 fl cf'>
					<div className='boxShadow br3 cf db relative'>
						<div className='creationHeader br3 br--top f4-ns f6 ba b--dark-gray'>
							<span className='tc w-100'><span className='word-break'>Table</span> of Contents</span>
						</div>
						<div className="overflow-auto creationTOCHeight">
							<Parent parent="Cover Page" previewCover={this.props.previewCover} coverPage={this.props.coverPage} editCover={this.props.editCover}/>
							<Parent parent="Table of Contents"/>
							{TOC}
							<div className=' w-100 cf bottom-0 absolute'>
								<div className='fl w-100 cf'>
									<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click' onClick={this.props.uploadDoc} >Upload Documents</button>
								</div>
								<div className='fl w-100 cf'>
									<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click' onClick={this.openProjectInfoModal} >Create Submittal</button>
								</div>
							</div>
						</div>

						
						
					</div>
				</div>
			</div>
		);
	}
}
CreationToc.propTypes = {
	toc: PropTypes.object.isRequired,
	removeDoc: PropTypes.func.isRequired,
	uploadDoc: PropTypes.func.isRequired,
	tocOrder: PropTypes.array.isRequired,
	handleReorder: PropTypes.func.isRequired,
	previewCover: PropTypes.func.isRequired,
	coverPage: PropTypes.string.isRequired,
	editCover: PropTypes.func.isRequired
}

class Parent extends Component {
	constructor(props){
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			showChildren: true
		}
	}

	handleClick(e){
		this.setState({showChildren: !this.state.showChildren});
	}

	render(){
		if(this.props.parentNav !== undefined){
			if(this.props.parentNav.length!==0){
				const icon = this.state.showChildren? '↑' : '↓';
				return(
					<div>
						<Fade>
							<div className='creationHeader ba shadow-click'>
								<div className='pl3 w-100 pv3'>
									<div onClick={this.handleClick}>
										<div className='fl w-90'>
											<span>{this.props.parent}</span>
										</div>
										<div className='fl w-10'>
											{icon}
										</div>
									</div>
								</div>
							</div>
							<Children parentNav={this.props.parentNav} toggleShow={this.state.showChildren} parent={this.props.parent} removeDoc={this.props.removeDoc}/>
						</Fade>
					</div>
				);
			}else{
				return null;
			}
		}else{
			const isCoverPage = this.props.parent === 'Cover Page' ? true : false;
			const previewIcon = isCoverPage ? <img src={Preview} alt='Preview' style={{height:'auto',width:'25px'}} />: null;
			const editIcon = isCoverPage ? <img src={edit_icon} alt="Edit" style={{height: 'auto', width:'25px'}} />: null;
			const coverPageHeaderClass = isCoverPage ? ' shadow-click': '';
			return(
				<div>
					<Fade>
						<div className='creationHeader ba'>
							<div className={'pl3 w-100 pv3'} >
								<div >
									<div className='fl w-100'>
										<div className={isCoverPage ? 'fl w-60-l w-100' : 'fl w-100'}>
											<span>{this.props.parent}</span>
										</div>
										<div className={isCoverPage ? 'fl w-40-l w-100 tc' : ''}>
											<div className={'fr w-50 ' + coverPageHeaderClass} onClick={this.props.previewCover}>
												{previewIcon}
											</div>
											<div className={'fl w-50 ' + coverPageHeaderClass} onClick={this.props.editCover}>
												{/* {editIcon} */}
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>
						
					</Fade>
				</div>
			);
		}
	}
}

Parent.propTypes = {
	parentNav: PropTypes.array,
	parent: PropTypes.string.isRequired,
	removeDoc: PropTypes.func,
	previewCover: PropTypes.func,
	editCover: PropTypes.func,
	coverPage: PropTypes.string
}

class Children extends Component {
	constructor(props){
		super(props);
	}
	render(){
		if(this.props.toggleShow){
			const child = this.props.parentNav.map((data,i)=>{
				return (
					<div  key={i}>
						<Fade>
							<div className='fl w-100 cf bg-black-20 bb'>
								<div className='fl w-80 br b--white creationDocHeader pa2 '>
									<span className='w-100 tc'>{data}</span>
								</div>
								<div className='fl w-20 creationDocHeader pa2  shadow-click' style={{display:'flex',justifyContent:'center'}} onClick={this.props.removeDoc} id={data} href={this.props.parent}>
									<img src={Minus} alt="Minus" id={data} style={{height:'auto',width:'25px'}} />
								</div>
							</div>
						</Fade>
					</div>
				);
			});

			return (
				<div>
					<Fade>
						<div className='fl w-100'>
							<div className='fl w-80 creationHeader bg-dark-gray'>
								<span className='w-100 tc'>Documents</span>
							</div>
							<div className='fl w-20 creationHeader bg-dark-gray bl b--white'>
								<span className='w-100 tc'>-</span>
							</div>
							<div className='fl w-100 f6'>
								{child}
							</div>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

Children.propTypes = {
	parentNav: PropTypes.array.isRequired,
	toggleShow: PropTypes.bool.isRequired,
	parent: PropTypes.string.isRequired,
	removeDoc: PropTypes.func.isRequired
}