import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');

const modalStyles = {
	content:{
		top: '35%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    transform: 'translate(-50%, -50%)'
	}
}

export default class MissingFields extends Component {
	constructor(props){
		super(props);
		this.handleCloseModal = this.handleCloseModal.bind(this);
	}

	handleCloseModal(){
		this.props.toggleModal('missingModal',false);
	}

	render(){
		return (
			<div>
				<Modal
				isOpen={this.props.state}
				contentLabel="Missing Required Fields"
				onRequestClose={this.handleCloseModal}
				style={modalStyles}>
					<h3 className='tc'>Missing required fields.</h3>
					<p>Please fill out all the required fields in red.</p>
					<br/>
				    <button className='bg-bottom f5 link dim br3 ph3 pv2 mb2 white w-100' onClick={this.handleCloseModal} style={{ backgroundColor : '#1965A3' }}>Close Modal </button>
				</Modal>
			</div>
			);
	}
}

MissingFields.propTypes = {
	state: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired
};