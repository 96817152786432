import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Modal from 'react-modal';
import '../asset/style/Option.css';
import Input from '../components/Input.js';
import Validation from '../components/Validation.js';
import $ from 'jquery';
import {coverpage_ajax} from '../components/API.js';
import {utoa, atou} from '../components/Encode.js';
import buildSubmittal from '../asset/img/Build-submittal.jpg';
import uploadSubmittal from '../asset/img/Upload-submittal.jpg';


Modal.setAppElement('#root');

const modalStyles = {
	content:{
		top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    transform: 'translate(-50%, -50%)',
	    backgroundBack: 'transparent',
	    padding: '0px',
	    borderRadius: '.5rem'
	 
	},
	overlay: {
		zIndex: 2
	}
}

export default class EditCover extends Component {
	constructor(props){
		super(props);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.updateProjectName = this.updateProjectName.bind(this);
		this.selectOption = this.selectOption.bind(this);
		this.updateAndChangeCover = this.updateAndChangeCover.bind(this);
		this.backButton = this.backButton.bind(this);
		this.state = {
			oldProjectName: '',
			coverPageOption: null,
			activePage: 'home'
		}
	}
	componentDidMount(){
		this.setState({'oldProjectName':this.props.projectName});
	}

	handleInput(state,newValue){
		// console.log(state,newValue);
		// console.log(this.props.projectName);
		// if(this.state.oldProjectName == ''){
		// 	this.setState({'oldProjectName':this.props.projectName});
		// }
		//This updates the cover page name in the state on Submittal.js
		//Need to update the database too so when mysubmittal is navigated to the data will update with the new coverpage data
		this.props.updateProjectName(state,newValue);
		const label_id = state+"_label";
		var x = document.getElementsByName(state);
		var y = document.getElementsByName(label_id);

		if(Validation(state,newValue)){
			x[0].style.borderColor = "";  	
			y[0].style.color = "";
		}else{
			x[0].style.borderColor = "red";  	
			y[0].style.color = "red";
		}
	}

	handleCloseModal(){
		this.props.updateProjectName('projectname',this.state.oldProjectName);
		this.props.toggleModal('editCoverModal',false);
		this.setState({coverPageOption:null, activePage:'home'});
	}

	updateProjectName(){
		$.ajax({
			url: coverpage_ajax,
			type: 'POST',
			data: {projectname: this.props.projectName, oldprojectname: this.state.oldProjectName, loginInfo: JSON.stringify(this.props.loginInfo)}
		}).done((json_data)=>{
			const data = JSON.parse(json_data);
			if(data.status=='success'){
				this.setState({'oldProjectName':this.props.projectName});
			}

		});

	}

	selectOption(e){
		const id = e.target.id;
		if(id === 'build'){

			document.getElementById('upload').style.border = '0px solid black';
		}else{
			document.getElementById('build').style.border = '0px solid black';
		}
		e.target.style.border = '2px solid black';
		this.setState({coverPageOption:e.target.id});
	}

	//Need to create a methond and a button to update the project and update the cover page
	updateAndChangeCover(){
		if(this.state.coverPageOption !== null){
			$.ajax({
				url: coverpage_ajax,
				type: 'POST',
				data: {projectname: this.props.projectName, oldprojectname: this.state.oldProjectName, loginInfo: JSON.stringify(this.props.loginInfo)}
			}).done((json_data)=>{
				const data = JSON.parse(json_data);
				if(data.status=='success'){
					this.setState({'oldProjectName':this.props.projectName,activePage: this.state.coverPageOption});
				}

			});
		}else{
			document.getElementById('upload').style.border = '3px solid red';
			document.getElementById('build').style.border = '3px solid red';

		}
	}

	backButton(){
		this.setState({activePage:'home', coverPageOption:null});
	}


	render(){
		if(this.state.activePage === 'home'){
			return(
				<div>
					<Fade>
						<Modal
						isOpen={this.props.state}
						contentLabel="Edit Cover Page"
						onRequestClose={this.handleCloseModal}
						style={modalStyles}
						shouldCloseOnOverlayClick={false}>
							<div className='fl w-100 cf' style={{width: '45vw'}}>
								<div className='fl w-100' >
									<div className='fl w-100 optionHeader br3 br--top f3 ba b--dark-gray tc mb2'>
										<p className='tc w-100' >Cover Sheet</p>
									</div>
									<div className='ma3'>
										<div className='fl w-100 f5'>
											<label htmlFor="projectName" name='projectname_label'><b>Project Name<span className='red'>*</span>:</b></label>
										</div>
										<div className='fl w-100'>
											<div className='fl w-70-l w-100'>
												<Input id='projectName' name='projectname' className='br2 w-100 pa1' onChange={this.handleInput} value={this.props.projectName} />
											</div>
											<div className='fl w-30-l w-100'>
												<button className='bg-bottom f7 link dim br3 ph3 pv1 white w-100 b--dark-gray bg-dark-gray' onClick={this.updateProjectName} >Update Project Name</button>			
											</div>
										</div>
										<div className='fl w-100 mt1'>
											<div className='fl w-50 pr2'>
												<img className='optionImg shadow-click grow' id='build' onClick={this.selectOption} src={buildSubmittal} alt="Build-Cover-Page" style={{border: '0px solid black'}} />
											</div>
											<div className='fr w-50 pl2'>
												<img className='optionImg shadow-click grow' id='upload' onClick={this.selectOption} src={uploadSubmittal} alt="Upload-Cover-Page" style={{border: '0px solid black'}} />
											</div>
										</div>
									</div>
								</div>
								<div className='fl w-100 mt3'>
									<button className='bg-bottom f7 link dim br3 ph3 pv2 white w-100 b--dark-gray bg-dark-gray' onClick={this.updateAndChangeCover} >Update Project Name and Change Cover Page</button>
									<button className='bg-bottom f7 link dim br3 ph3 pv2 white w-100' onClick={this.handleCloseModal} style={{ backgroundColor : '#1965A3' }}>Close Preview</button>
								</div>
							</div>
						</Modal>
					</Fade>
				</div>
			);
		}else if(this.state.activePage === 'upload'){
			return(
				<div>
					<Fade>
						<UploadCover handleCloseModal={this.handleCloseModal} state={this.props.state} backButton={this.backButton} projectName={this.state.oldProjectName} loginInfo={this.props.loginInfo} />
					</Fade>
				</div>
			);
		}else if(this.state.activePage === 'build'){
			return(
				<div>	
					<Fade>
						<BuildCover handleCloseModal={this.handleCloseModal} state={this.props.state} backButton={this.backButton} projectName={this.state.oldProjectName} loginInfo={this.props.loginInfo} />
					</Fade>
				</div>
			);
		}
	}

}

EditCover.propTypes = {
	state: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired,
	projectName: PropTypes.string.isRequired,
	updateProjectName: PropTypes.func.isRequired,
	loginInfo: PropTypes.object.isRequired
}


class UploadCover extends Component {
	constructor(props){
		super(props);
	}

	render(){
		return (
			<div>
				<Modal
				isOpen={this.props.state}
				contentLabel="Edit Cover Page"
				onRequestClose={this.props.handleCloseModal}
				style={modalStyles}
				shouldCloseOnOverlayClick={false}>
					<div className='fl w-100 cf' style={{width: '45vw'}}>
						<div className='fl w-100' >
							<div className='fl w-100 optionHeader br3 br--top f3 ba b--dark-gray tc mb2'>
								<p className='tc w-100' >Upload Cover Sheet</p>
							</div>
							<div className='ma3'>
								<div className='fl w-100 f5'>
									<label htmlFor="projectName" name='projectname_label'><b>Project Name:</b></label>
								</div>
								<div className='fl w-100 cf'>

									<Input id='projectName' name='projectName' className='br2 w-100 pa1' value={this.props.projectName} />
								</div>
							</div>
						</div>
						<div className='fl w-100 mt3'>
							<button className='bg-bottom f7 link dim br3 ph3 pv2 white w-100 b--dark-gray bg-dark-gray' onClick={this.props.backButton} >Back</button>
							<button className='bg-bottom f7 link dim br3 ph3 pv2 white w-100' onClick={this.props.handleCloseModal} style={{ backgroundColor : '#1965A3' }}>Close Preview</button>
						</div>
					</div>
				</Modal>

			</div>
		);
	}
}

UploadCover.propTypes = {
	state: PropTypes.bool.isRequired,
	handleCloseModal: PropTypes.func.isRequired,
	backButton: PropTypes.func.isRequired,
	projectName: PropTypes.string.isRequired,
	loginInfo: PropTypes.object.isRequired
}

class BuildCover extends Component {
	constructor(props){
		super(props);
	}

	render(){
		return (
			<div>
				<Modal
				isOpen={this.props.state}
				contentLabel="Edit Cover Page"
				onRequestClose={this.props.handleCloseModal}
				style={modalStyles}
				shouldCloseOnOverlayClick={false}>
					<div className='fl w-100 cf' style={{width: '45vw'}}>
						<div className='fl w-100' >
							<div className='fl w-100 optionHeader br3 br--top f3 ba b--dark-gray tc mb2'>
								<p className='tc w-100' >Build Cover Sheet</p>
							</div>
						</div>
						<div className='ma3'>
							<div className='fl w-100 f5'>
								<label htmlFor="projectName" name='projectname_label'><b>Project Name:</b></label>
							</div>
							<div className='fl w-100 cf'>

								<Input id='projectName' name='projectName' className='br2 w-100 pa1' value={this.props.projectName} />
							</div>
						</div>
						<div className='fl w-100 mt3'>
							<button className='bg-bottom f7 link dim br3 ph3 pv2 white w-100 b--dark-gray bg-dark-gray' onClick={this.props.backButton} >Back</button>
							<button className='bg-bottom f7 link dim br3 ph3 pv2 white w-100' onClick={this.props.handleCloseModal} style={{ backgroundColor : '#1965A3' }}>Close Preview</button>
						</div>
					</div>
				</Modal>

			</div>
		);
	}
}

BuildCover.propTypes = {
	state: PropTypes.bool.isRequired,
	handleCloseModal: PropTypes.func.isRequired,
	backButton: PropTypes.func.isRequired,
	projectName: PropTypes.string.isRequired,
	loginInfo: PropTypes.object.isRequired

}
