import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Modal from 'react-modal';
import '../asset/style/Creation.css';
import Input from '../components/Input.js';
import Validation from '../components/Validation.js';
import $ from 'jquery';
import {creation_ajax} from '../components/API.js';
import {atou, utoa} from '../components/Encode.js';
import Loading from '../components/Loading.js';
import Preview from '../asset/img/preview_icon.png';
import downloadPDF from '../components/DownloadPDF.js';

Modal.setAppElement('#root');

const modalStyles = {
	content:{
		top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    transform: 'translate(-50%, -50%)',
	    backgroundBack: 'transparent',
	 
	},
	overlay:{
		zIndex: 2,
	}
}

export default class Reorder extends Component {
	constructor(props){
		super(props);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleCreateSubmittal = this.handleCreateSubmittal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.state = {
			loadingModal:false,
		}
	}

	handleCloseModal(){
		this.props.toggleModal('reorderModal',false);
	}

	handleInput(state,newValue){
		const newState = {};
		newState[state] = newValue;
		this.setState(newState);
	}

	handleCreateSubmittal(e){
		//Validate all the page ranges are properly formated
		let validRange = true;
		var pageRange = this.props.pageRange;
		var parsedPageRange = {
			'Piping Systems':[],
			'Valves and Actuation':[],
			'Sensors and Instrumentation':[],
			'User Documents':[],
			'Appendix':[]
			};
		for(const key in pageRange){
			const value_array = pageRange[key];
			const parsedGroupRange = [];
			for(const range of value_array){
				range = range == null ? '' : range;
				if(Validation('pageRange',range,false)){
					parsedGroupRange.push(range.split(';'));

				}else{
					parsedGroupRange.push(['']);
					this.props.updatePageRange(key+'_'+value_array.indexOf(range),'');

				}
			}
			parsedPageRange[key] = parsedGroupRange;
		}

		
		const cover_page = ["_header","Cover Page",this.props.coverPage];
		const toc = ["_header","Table of Contents"];
		const submittal_array = [cover_page,toc];
		for(const index in this.props.tocOrder){
			const header = this.props.tocOrder[index];
			const docs = this.props.toc[header];

			const toc_header_entry = [];
			toc_header_entry.push("_header",header);
			if(docs.length==0){
				toc_header_entry.push("hidden");
			}
			submittal_array.push(toc_header_entry);

			for(const docIndex in docs){
				const toc_doc_entry = ["_doc"];
				toc_doc_entry.push(docs[docIndex],header,header,parsedPageRange[header][docIndex]);
				submittal_array.push(toc_doc_entry);
			}
		}
		
		this.props.toggleModal('reorderModal',false);
		this.setState({'loadingModal':true});
		$.ajax({
			url:creation_ajax,
			type:"POST",
			data:{"submittal_array":submittal_array,"token":this.props.loginInfo.token,"projectName":this.props.projectName}
		}).done((json_data)=>{
			const data = JSON.parse(json_data);
			const loginInfo = {};
			for(const key in this.props.loginInfo){
				const value = this.props.loginInfo[key];
				if(key === 'completeSub'){
					value = utoa(atou(value)+1);
				}
				loginInfo[key] = value;
			}

			this.props.updateLogin(loginInfo);
			
			this.props.navigation(this.props.pageActive,'CreationHome','MySubmittal');
			
		}).always(()=>{
			this.setState({'loadingModal':false});
		});
		
	
	}

	render(){
		const TOC = this.props.tocOrder.map((data,i)=>{
			return <Parent key={i} parent={data} parentNav={this.props.toc[data]} reorderHeader={this.props.reorderHeader} reorderDocument={this.props.reorderDocument} pageRange={this.props.pageRange} updatePageRange={this.props.updatePageRange} />;
		});
		return (
			<div>
				<Loading toggleModal={this.handleInput} state={this.state.loadingModal} alt="Loading Documents..."/>
				<Modal
				isOpen={this.props.state}
				contentLabel="Reorder Submittal"
				onRequestClose={this.handleCloseModal}
				style={modalStyles}
				shouldCloseOnOverlayClick={false}>
					<div className='creationDiv fl w-100 cf pa1' style={{width:'70vw', minHeight:'0vh'}}>
						<div className='creationHeader br3 br--top f4-ns f6 ba b--dark-gray'>
							<span className='tc w-100'><span className='word-break'>Reordering Table</span> of Contents</span>
						</div>
						<div className='overflow-auto cf' style={{maxHeight: '65vh'}}>
							<Parent parent="Cover Page"/>
							<Parent parent="Table of Contents"/>
							{TOC}
							
						</div>
						<div className='w-100 mt2'>
							<div className='fl w-100'>
								<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click' onClick={this.handleCloseModal} >Close</button>
							</div>
							<div className='fl w-100'>
								<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click' onClick={this.handleCreateSubmittal} >Create Submittal</button>
							</div>
						</div>
					</div>

				</Modal>
			</div>
		);
	}

}

Reorder.propTypes = {
	navigation: PropTypes.func.isRequired,
	pageActive: PropTypes.object.isRequired,
	state: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired,
	toc: PropTypes.object.isRequired,
	pageRange: PropTypes.object.isRequired,
	reorderHeader: PropTypes.func.isRequired,
	reorderDocument: PropTypes.func.isRequired,
	updatePageRange: PropTypes.func.isRequired,
	loginInfo: PropTypes.object.isRequired,
	projectName: PropTypes.string.isRequired,
	updateLogin: PropTypes.func.isRequired,
	
};

class Parent extends Component {
	constructor(props){
		super(props);
	}

	render(){
		if(this.props.parentNav !== undefined){
			if(this.props.parentNav.length !== 0){
				return (
					<div>
						<Fade>
							<div className='creationHeader ba'>
								<div className='pl3 w-100 pv3'>
									<div className='fl w-80'>
										<span>{this.props.parent}</span>
									</div>
									<div className='fl w-20'>
										<div className='fl w-50 tc shadow-click grow' onClick={this.props.reorderHeader} direction='up' header={this.props.parent}>
											<span className='f4'>↑</span>
										</div>
										<div className='fl w-50 tc shadow-click grow' onClick={this.props.reorderHeader} direction='down' header={this.props.parent}>
											<span className='f4'>↓</span>
										</div>
									</div>
									
								</div>

							</div>
							<Children parentNav={this.props.parentNav} parent={this.props.parent} reorderDocument={this.props.reorderDocument} pageRange={this.props.pageRange[this.props.parent]} updatePageRange={this.props.updatePageRange}/>
						</Fade>
					</div>
				);
			}else{
				return null;
			}
		}else{
			return(
				<div>
					<Fade>
						<div className='creationHeader ba'>
							<div className='pl3 w-100 pv3'>
								<div>
									<div className='fl w-100'>
										<span>{this.props.parent}</span>
									</div>
								</div>
							</div>
						</div>
					</Fade>
				</div>
			);
		}
	}
}

Parent.propTypes = {
	parent: PropTypes.string.isRequired,
	reorderHeader: PropTypes.func,
	reorderDocument: PropTypes.func,
	updatePageRange: PropTypes.func,
	parentNav: PropTypes.array,
	pageRange: PropTypes.object
}



class Children extends Component {
	constructor(props){
		super(props);
		this.previewDoc = this.previewDoc.bind(this);
	}

	previewDoc(e){

		const preview_Link = "https://docs.google.com/viewerng/viewer?url=https://gfpsresourcecenter.blob.core.windows.net/resource/"+e.currentTarget.attributes.doc.value;

		downloadPDF(preview_Link);

	}

	render(){
		const inputStyle = {borderRadius:'.25rem',width:'100%',padding:'.25rem' };
		const child = this.props.parentNav.map((data,i)=>{
			const disabledVariable = data == 'Quality Manual for Installation of GF Piping Systems Products.pdf' ? true : false;
			return (
				<div key={i}>
					<Fade>
						<div className='fl w-100 bg-black-20 bb h-auto'>
							<div className='fl w-100 b--white creationDocHeader bl h2-ns h3-m h-auto'>
								<div className='fl' style={{width:'64%'}}>
									<div className='fl w-90 pl3 f6'>
										{data}
									</div>
									<div className='fl w-10 tc shadow-click' doc={data} onClick={this.previewDoc}>
										<img src={Preview} alt='Preview' style={{height:'20px', width:'auto'}}/>
									</div>

								</div>
								<div className='fl tc bl br shadow-click f7' style={{width:'16%'}}>
									
									<Input id={data} name={this.props.parent+"_"+i} style={inputStyle} onChange={this.props.updatePageRange} value={this.props.pageRange[i]} encode={false} placeholder='ex:1-7;8;15-16' disabled={disabledVariable}/>
								</div>
							
								<div className='fl w-10 tc shadow-click' onClick={this.props.reorderDocument} document={data} direction='up' parent={this.props.parent}>
									<div className='w-100 tc f3 f6-ns f5-l f4-m'>↑</div>
								</div>
								<div className='fl w-10 tc bl shadow-click' onClick={this.props.reorderDocument} document={data} direction='down' parent={this.props.parent}>
									<div className='w-100 tc f3 f6-ns f5-l f4-m'>↓</div>
								</div>
							</div>
						</div>
					</Fade>
				</div>
			);
		});

		return (
			<div>
				<Fade>
					<div className='fl w-100'>
						<div className='fl w-80 creationHeader bg-dark-gray bl bt bb h2'>
							<div className='fl w-80 tc br'>
								<span className='w-100 tc'>Documents</span>
							</div>
							<div className='fl w-20 tc bl br'>
								<span className='w-100 tc'>Page Range</span>
							</div>
						</div>
						<div className='fl w-20 creationHeader bg-dark-gray br bt bb h2'>
							<div className='fl w-50 tc bl br'>
								<span className='w-100 tc'>Up</span>
							</div>
							<div className='fl w-50 tc bl'>
								<span className='w-100 tc'>Down</span>
							</div>
						</div>
						<div className='fl w-100'>
							{child}
						</div>
					</div>
				</Fade>
			</div>
		);
	}
}

Children.propTypes = {
	parentNav: PropTypes.array.isRequired,
	pageRange: PropTypes.array.isRequired,
	parent: PropTypes.string.isRequired,
	reorderDocument: PropTypes.func.isRequired,
	updatePageRange: PropTypes.func.isRequired
}




