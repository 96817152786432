import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../asset/style/Login.css';
import Input from '../components/Input.js';
import $ from 'jquery';
import Fade from 'react-reveal/Fade';
import {registration_ajax} from '../components/API.js';
import Validation from '../components/Validation.js';
import Loading from '../components/Loading.js';

export default class Registration extends Component{
	constructor(props){
		super(props);
		this.handleHome = this.handleHome.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getSecurityQuestion = this.getSecurityQuestion.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
		this.createAccount = this.createAccount.bind(this);
		this.handleModal = this.handleModal.bind(this);
		this.state = {security_question: [], security1: 'null', security2: 'null', loadingModal: false};
	}

	handleHome(){
		this.props.navigation(this.props.pageActive,'Registration','Login');
	}

	handleModal(state,newValue){
		const newState = {};
		newState[state]=newValue;
		this.setState(newState);
	}

	handleInput(state,newValue){
		const newState = {};
		newState[state]=newValue;
		this.setState(newState);

		var x = document.getElementsByName(state);
		if(Validation(state,newValue)){
			x[0].style.borderColor = "";  	
		}else{
			x[0].style.borderColor = "red";  	
		}
	}

	handleSelect(e){
		this.handleInput(e.target.id,e.target.value);
	}

	componentDidMount(){
		if(this.props.pageActive.Login){
			$.ajax({
				url:registration_ajax,
				type:"POST",
				data:{getQuestions:'getQuestions'}
			}).done((json_data)=>{
				const data = JSON.parse(json_data);
				if(typeof(data)==='object'){
					if(data.status==='success'){
						this.setState({'security_question':data.securityQuestions});
					}
				}
			});
		}
	}

	getSecurityQuestion(exclude=null){
		const security_questions = this.state.security_question.map((option, i)=>{ 
			if(option!==exclude){
				return <option value={option} key={i}>{option}</option>;
			}
		});
		return security_questions;
	}

	handleRegister(){
		let requiredFields = {
			'firstName' : false,
			'lastName' : false,
			'email' : false,
			'uname' : false,
			'pword' : false,
			'security1' : false,
			'answer1' : false,
			'security2' : false,
			'answer2' : false
		};

		for (var userInput in this.state){
			if(userInput in requiredFields){
				requiredFields[userInput] = (Validation(userInput,this.state[userInput].trim())) ? true : false;
			}
		}
		requiredFields['security1'] = this.state.security1.trim()!=='null' ? true : false ;
		requiredFields['security2'] = this.state.security2.trim()!=='null' ? true : false ;

		let requiredState = { 'true':0, 'false':0 };

		Object.keys(requiredFields).forEach(key=>{
			if(!requiredFields[key]){
				requiredState.false+=1;
				var x = document.getElementsByName(key);
				x[0].style.borderColor = "red";
			}else{
				requiredState.true+=1;
			}
		});
		if(requiredState.true === Object.keys(requiredFields).length){
			this.createAccount();
		}
	}

	createAccount(){
		//make ajax call to create account and then navigate away after finishing.
		this.handleModal('loadingModal',true);
		const states = this.state;
		$.ajax({
			url:registration_ajax,
			type:"POST",
			data: {"createAccount":states}
		}).done((json_data)=>{
			const data = JSON.parse(json_data);
			if(data.status==='success'){
				alert('Your account is being setup right now. Please check your email to activate your account...');
				this.handleHome();
			}else if(data.status==='fail'){
				if(data.reason==='duplicate'){
					alert('Unable to create an account, username has been taken...');
				}else{
					alert('Unable to create an account, please try again...');
				}
			}
		}).always(()=>{
			this.handleModal('loadingModal', false);
		});
	}

	render(){
		if(this.props.pageActive.Registration){
			const inputStyle= "br2 w-100 pa1";
			const divStyle= "fl pa2 w-100";
			const divStyle_third= "fl w-third mt1";
			const divStyle_two_third= "fl w-two-thirds";
			
			return (
				<div>
					<Fade >
						<Loading toggleModal={this.handleModal} state={this.state.loadingModal} alt="Loading Documents..."/>
						<div className='w-auto pa3 br3 reg-divsection'>
							<h1>Request an Account</h1>
							<p><em>Please fill out the information below for an account!.</em></p>
							<p className='red'>* Required Fields</p>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='firstName'>First Name<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<Input id='firstName' name='firstName' className={inputStyle} onChange={this.handleInput}/>
								</div>
							</div>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='lastName'>Last Name<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<Input id='lastName' name='lastName' className={inputStyle} onChange={this.handleInput}/>
								</div>
							</div>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='email'>Email<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<Input id='email' name='email' className={inputStyle} onChange={this.handleInput}/>
								</div>
							</div>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='uname'>Username<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<Input id='uname' name='uname' className={inputStyle} onChange={this.handleInput}/>
								</div>
							</div>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='pword'>Password<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<Input id='pword' type='password' name='pword' className={inputStyle} onChange={this.handleInput}/>
								</div>
							</div>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='security1'>Security<br/>Question 1<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<select id='security1' name='security1' className='w-100' style={{height:'2.5rem', fontSize:'1rem'}} onChange={this.handleSelect}>
										<option value="null">Select a security question...</option>
										{this.getSecurityQuestion(this.state.security2)}
									</select>
								</div>
							</div>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='answer1'>Security<br/>Answer 1<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<Input id='answer1' name='answer1' className={inputStyle} onChange={this.handleInput}/>
								</div>
							</div>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='security2'>Security<br/>Question 2<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<select id='security2' name='security2' className='w-100' style={{height:'2.5rem', fontSize:'1rem'}} onChange={this.handleSelect}>
										<option value="null">Select a security questions...</option>
										{this.getSecurityQuestion(this.state.security1)}
									</select>
								</div>
							</div>
							<div className={divStyle}>
								<div className={divStyle_third}>
									<label htmlFor='answer2'>Security<br/>Answer 2<span className='red'>*</span>:</label>
								</div>
								<div className={divStyle_two_third}>
									<Input id='answer2' name='answer2' className={inputStyle} onChange={this.handleInput}/>
								</div>
							</div>
							<div className='fl w-100 pt2'>
								<div className='fl w-50'>
									<button className='bg-bottom f5 link dim br3 pv2 mb2 white w-100' onClick={this.handleHome} style={{ backgroundColor : '#000000' }}>Cancel</button>
								</div>
								<div className='fl w-50'>
									<button className='bg-bottom f5 link dim br3 pv2 mb2 white w-100' onClick={this.handleRegister} style={{ backgroundColor : '#000000' }}>Register</button>
								</div>
							</div>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

Registration.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired
}