import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import '../asset/style/Option.css';
import Input from '../components/Input.js';
import Validation from '../components/Validation.js';
import $ from 'jquery';
import {coverpage_ajax} from '../components/API.js';
import {atou} from '../components/Encode.js';
import Loading from '../components/Loading.js';

export default class UploadCover extends Component {
	constructor(props){
		super(props);
		this.handleInput = this.handleInput.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleError = this.handleError.bind(this);
		this.handleModal = this.handleModal.bind(this);
		this.state = {
			coverfileupload: false,
			filedetails: 'No PDF Cover Uploaded',
			loadingModal: false,
		}
	}

	handleInput(state,newValue){
		this.props.updateProjectName(state,newValue);

		const label_id = state+"_label";
		var x = document.getElementsByName(state);
		var y = document.getElementsByName(label_id);

		if(Validation(state,newValue)){
			x[0].style.borderColor = "";  	
			y[0].style.color = "";
		}else{
			x[0].style.borderColor = "red";  	
			y[0].style.color = "red";
		}
	}


	handleUpload(e){
		const file = e.target.files[0];
		const id = e.currentTarget.id;
		const label_id = id + "_label";
		const x = document.getElementsByName(label_id);
		const y = document.getElementsByName(id);

		let filedetails;
		if(file !== undefined){
			if(file.type !== 'application/pdf'){
				x[0].style.color = 'red';
				y[0].style.borderColor = 'red';
				filedetails = "File is not a pdf. Please upload a pdf cover or create one.";
			}else{
				x[0].style.color = '';
				y[0].style.borderColor = '';
				let details = [];
				details.push(file.name,' (',file.type || 'n/a', ') - ',file.size, ' bytes, last modified: ', file.lastModifiedDate ? file.lastModifiedDate.toLocaleDateString() : 'n/a');
				filedetails = details.join('');
			}
			this.setState({'coverfileupload':e.target.files[0], 'filedetails':filedetails});
		}else{
			
			x[0].style.color = 'red';
			y[0].style.borderColor = 'red';
			this.setState({'coverfileupload':false, 'filedetails':'No PDF Cover Uploaded'});
		}
	}

	handleModal(state,newValue){
		const newState = {};
		newState[state] = newValue;
		this.setState(newState);
	}

	handleSubmit(){

		if(this.handleError()){
			this.handleModal('loadingModal',true);
			let data = new FormData();
			data.append('projectname',this.props.projectName);
			data.append('loginInfo',JSON.stringify(this.props.loginInfo));
			data.append('coverfileupload',this.state.coverfileupload);
			$.ajax({
				url: coverpage_ajax+"?uploadFile="+this.props.projectName,
				type: 'POST',
				data: data,
				contentType:false,
				processData:false,
				cache:false
			}).done((json_data)=>{
				try{
					console.log(json_data);
					const data = JSON.parse(json_data);
					let coverPage = '';
					let nextPage = 'MySubmittal';
					if(data.status==='success'){
						this.props.updateLogin(data.loginInfo);
						coverPage = atou(this.props.loginInfo.token)+"-"+atou(this.props.projectName)+"-"+this.state.coverfileupload.name;
						nextPage = 'CreationHome';
					}
					const newState_array = {
						coverpage: coverPage,
						childDoc: '',
						parentDoc: '',
						pageRange: {
							'Piping Systems':[],
							'Valves and Actuation':[],
							'Sensors and Instrumentation':[],
							'User Documents':[]
						},
						submittalTOC: {
							'Piping Systems':[],
							'Valves and Actuation':[],
							'Sensors and Instrumentation':[],
							'User Documents':[]
						},
						order: ["Piping Systems", "Valves and Actuation", "Sensors and Instrumentation", "User Documents"]
					};
					this.props.updateProjectData(newState_array);
					this.props.navigation(this.props.pageActive, 'UploadCover', nextPage);
				}catch(e){
					console.log(e);
				}
			}).always(()=>{
				this.handleModal('loadingModal',false);
			});

		}
	}

	handleError(){
		let coverState = false;
		let projectNameState = false;

		const x = document.getElementsByName('coverfileupload');
		const x_2 = document.getElementsByName('coverfileupload_label');
		if(this.state.coverfileupload){
			x_2[0].style.color = '';
			x[0].style.borderColor = '';
			coverState = true;
		}else{
			x_2[0].style.color = 'red';
			x[0].style.borderColor = 'red';
		}

		const y = document.getElementsByName('projectname');
		const y_2 = document.getElementsByName('projectname_label');
		if(this.props.projectName){
			y_2[0].style.color = '';
			y[0].style.borderColor = '';
			projectNameState = true;
		}else{
			y_2[0].style.color = 'red';
			y[0].style.borderColor = 'red';			
		}

		if(coverState && projectNameState){
			return true;
		}else{
			return false;
		}
	}

	render(){
		if(this.props.pageActive.UploadCover){
			return (
				<div className='zIndex-2'>
					<Fade>
						<Loading toggleModal={this.handleModal} state={this.state.loadingModal} alt="Loading Documents..."/>
						<div className='optionDiv w-auto br3 cf' style={{minHeight: '25vh'}}>
							<div className='fl w-100 optionHeader br3 br--top f3 ba b--dark-gray'>
								<p className='tc w-100' >Cover Sheet</p>
							</div>

							<div className='fl w-100 pa4 pb3'>
								<div className='fl w-100 f5'>
									<label htmlFor="projectname" name='projectname_label'><b>Project Name<span className='red'>*</span>:</b></label>
								</div>
								<div className='fl w-100 pt2'>
									<Input id='projectname' name='projectname' className='br2 w-100 pa1' onChange={this.handleInput} value={this.props.projectName} />
								</div>
							</div>

							<div className='fl w-100 ph4'>
								<div className='fl w-100 f5'>
									<label htmlFor="coverfileupload" name='coverfileupload_label'><b>File Upload<span className='red'>*</span>:</b><span className='red'>(PDF Only 5MB Limit)</span></label>
								</div>
								<div className='fl w-100 pt2'>
									<input type='file' id='coverfileupload' name='coverfileupload' className='br2 ba bg-white w-100 fileUpload pv1' onChange={this.handleUpload} ref={(ref)=>{this.uploadInput = ref;}}  />
								</div>
							</div>

							<div className='fl w-100 ph4 pt3'>
								<div className='fl w-100 f5'>
									<label htmlFor="fileinfo" name='fileinfo_label'><b>File Information:</b></label>
								</div>
								<div className='fl w-100'>
									<i><ul><li>{this.state.filedetails}</li></ul></i>
								</div>
							</div>

							<div className='fl w-100 ph4 pv3'>
								<button className='bg-bottom f5 link dim br3 pv2 mb2 white w-100'  style={{ backgroundColor : '#1965A3', boxShadow: '3px 2.5px 12px .5px rgba(0,0,0,0.9)'}} onClick={this.handleSubmit}>Upload</button>
					
							</div>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

UploadCover.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired,
	projectName: PropTypes.string.isRequired,
	loginInfo: PropTypes.object.isRequired,
	updateLogin: PropTypes.func.isRequired,
	updateProjectName: PropTypes.func.isRequired,
	updateProjectData: PropTypes.func.isRequired,
}