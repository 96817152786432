import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Modal from 'react-modal';
import '../asset/style/Option.css';
import Input from '../components/Input.js';
import $, { parseJSON } from 'jquery';
import Loading from '../components/Loading.js';
import '../asset/style/Creation.css';
import {utoa, atou} from '../components/Encode.js';
import {creation_ajax} from '../components/API.js';


Modal.setAppElement('#root');

const modalStyles = {
	content:{
		top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    transform: 'translate(-50%, -50%)',
	    backgroundBack: 'transparent',
	    padding: '0px',
	    borderRadius: '.5rem'
	 
	},
	overlay: {
		zIndex: 2
	}
}

export default class ProjectInfo extends Component {
    constructor(props){
        super(props);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state={
            companyName:'',
            employeeTitle:'',
            date: (new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-')+ (new Date().getDate().length>1?new Date().getDate():'0'+new Date().getDate()),
            projectLocation:'',

            companyName_Label:'',
            date_Label:'',
            employeeName_Label:'',
            employeeTitle_Label:'',
            projectName_Label:'',
            projectLocation_Label:''
        }
        
    }
    handleCloseModal(){
		this.props.toggleModal('projectInfoModal',false);
	}

    handleInput(state,newValue){
		const newState = {};
		newState[state]=newValue;
		this.setState(newState);
	}

    componentDidMount(){
        const data = {};
        $.ajax({
            url:creation_ajax,
            type:"POST",
            data:{"getProjectInfo":this.props.projectName,"token":this.props.loginInfo.token}
        }).done((json_data)=>{
            try{
                const projectInfo = JSON.parse(json_data)['data'];
                this.setState({
                    companyName:projectInfo['company_name']==null?'':projectInfo['company_name'],
                    employeeTitle:projectInfo['position_title']==null?'':projectInfo['position_title'],
                    date:projectInfo['date']==null?'':projectInfo['date'],
                    projectLocation:projectInfo['project_location']==null?'':projectInfo['project_location']
                });
            }catch(err){
                this.setState({
                    companyName:'',
                    employeeTitle:'',
                    date:'',
                    projectLocation:''
                });
            }
        }).fail((error)=>{
            this.setState({
                companyName:'',
                employeeTitle:'',
                date:'',
                projectLocation:''
            });
        });

    }

    handleSubmit(){

        const required = {
            companyName:this.state.companyName.length>0?true:false,
            date:this.state.date.length>0?true:false,
            employeeName: (this.props.loginInfo.firstName+' '+this.props.loginInfo.lastName).length>0?true:false,
            employeeTitle:this.state.employeeTitle.length>0?true:false,
            projectName: this.props.projectName.length>0?true:false,
            projectLocation: this.state.projectLocation.length>0?true:false
        };
        const projectInfo = {
            companyName:this.state.companyName,
            date:utoa(this.state.date),
            employeeName: utoa(atou(this.props.loginInfo.firstName)+' '+atou(this.props.loginInfo.lastName)),
            employeeTitle:this.state.employeeTitle,
            projectName: this.props.projectName,
            projectLocation: this.state.projectLocation
        };
        
        let allRequired = true;
        const labels = {};
        Object.entries(required).forEach(val=>{
            if(!val[1]){
                labels[val[0]+'_Label']='red'
                allRequired = false;
            }else{
                labels[val[0]+'_Label']='';
            }
        });
        this.setState(labels);
        if(allRequired){
            this.props.submit(projectInfo);
        }else{
            console.log(projectInfo);
        }
    }

    render(){
        const input_label = 'fl w-100 ph3 mt1 mb4';
        return(
            <div>
                <Modal
                isOpen={this.props.state}
                contentLabel="Project Information"
                onRequestClose={this.handleCloseModal}
                style={modalStyles}
                shouldCloseOnOverlayClick={false}>
                    <div className='fl w-100 cf pa0 relative overflow-hidden' style={{width:'60vw'}}>
                        <div className='creationHeader pv2 f3 br2'>
							<span className='tc w-100'>Submittal Information</span>
						</div>

                        <div className='fl w-100 pa2 pb5'>
                            <h4 className='tl ma3'>Company Information</h4>
                            <hr/>   
                            <div className={input_label}>
                                <div className={'fl w-100 f5 ' + this.state.companyName_Label}>
                                    <label htmlFor="companyName" name='companyName_label'>Company Name<span className='red'>*</span>:</label>
                                </div>
                                <div className='fl w-100 pt2'>
                                    <Input id='companyName' name='companyName' className='br2 w-100 pa1'  onChange={this.handleInput} value={this.state.companyName}  />
                                </div>
                                <div className={'fl w-100 f5 pt2 ' + this.state.employeeName_Label}>
                                    <label htmlFor="employeeName" name='employeeName_label'>Employee Name<span className='red'>*</span>:</label>
                                </div>
                                <div className='fl w-100 pt2'>
                                    <Input id='employeeName' name='employeeName' className='br2 w-100 pa1'  value={atou(this.props.loginInfo.firstName) +' '+atou(this.props.loginInfo.lastName)} encode={false} />
                                </div>
                                <div className={'fl w-100 f5 pt2 ' + this.state.employeeTitle_Label}>
                                    <label htmlFor="employeeTitle" name='employeeTitle_label'>Employee Title<span className='red'>*</span>:</label>
                                </div>
                                <div className='fl w-100 pt2'>
                                    <Input id='employeeTitle' name='employeeTitle' className='br2 w-100 pa1'  value={this.state.employeeTitle} onChange={this.handleInput}  />
                                </div>
                                
                            </div>

                            <h4 className='tl ma3'>Project Information</h4>
                            <hr/>  
                            <div className={input_label}>
                                <div className={'fl w-100 f5 ' + this.state.projectName_Label}>
                                    <label htmlFor="projectName" name='projectname_label'>Project Name<span className='red'>*</span>:</label>
                                </div>
                                <div className='fl w-100 pt2'>
                                    <Input id='projectName' name='projectname' className='br2 w-100 pa1'  value={this.props.projectName} />
                                </div>

                                <div className={'fl w-100 f5 pt2 ' + this.state.date_Label}>
                                    <label htmlFor="date" name='date_label'>Date<span className='red'>*</span>:</label>
                                </div>
                                <div className='fl w-100 pt2'>
                                    <Input id='date' name='date' type='date' className='br2 w-100 pa1'  value={this.state.date} onChange={this.handleInput} encode={false} />
                                </div>
                                <div className={'fl w-100 f5 pt2 ' + this.state.projectLocation_Label}>
                                    <label htmlFor="projectLocation" name='projectLocation_label'>Project Location<span className='red'>*</span>:</label>
                                </div>
                                <div className='fl w-100 pt2'>
                                    <Input id='projectLocation' name='projectLocation' className='br2 w-100 pa1'  value={this.state.projectLocation} onChange={this.handleInput}  />
                                </div>
                            </div>
                        </div>

                        <div className='w-100 mt2 absolute bottom-0'>
							<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click' onClick={this.handleCloseModal}>Close</button>
                            <button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click' onClick={this.handleSubmit} >Submit</button>
						</div>
                    </div>
                </Modal>
            </div>
        );
    }
}


ProjectInfo.propTypes = {
    state: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    loginInfo: PropTypes.object.isRequired,
	projectName: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired
}

