import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import '../asset/style/Option.css';
import Input from '../components/Input.js';
import Validation from '../components/Validation.js';
import $ from 'jquery';
import {utoa, atou} from '../components/Encode.js';
import MissingFields from '../components/MissingFields.js';
import Loading from '../components/Loading.js';
import {coverpage_ajax} from '../components/API.js';

export default class BuildCover extends Component {
	constructor(props){
		super(props);
		this.handleInput = this.handleInput.bind(this);
		this.handleTextarea = this.handleTextarea.bind(this);
		this.handleBuildCover = this.handleBuildCover.bind(this);
		this.buildCover = this.buildCover.bind(this);
		this.state={
			missingModal:false,
			loadingModal:false
		}
	}

	buildCover(){
		this.handleInput('loadingModal',true);
		const modals = ["missingModal", "loadingModal"];
		let data = {};
		for(var state_key in this.state){
			if(modals.indexOf(state_key)===-1){
				data[state_key] = this.state[state_key];
			}
		}
		data['projectname'] = this.props.projectName;
		data['loginInfo'] = this.props.loginInfo;

		
		$.ajax({
			url: coverpage_ajax,
			type: 'POST',
			data: {data:JSON.stringify(data)}
		}).done((json_data)=>{
			const data = JSON.parse(json_data);
			this.props.updateLogin(data.loginInfo);
			
			const newState_array = {
				coverpage: atou(this.props.loginInfo.token)+"-"+atou(this.props.projectName)+"-"+atou(this.state.projectcontact1)+".pdf",
				childDoc: '',
				parentDoc: '',
				pageRange: {
					'Piping Systems':[],
					'Valves and Actuation':[],
					'Sensors and Instrumentation':[],
					'User Documents':[],
					'QA/QC':[]
				},
				submittalTOC: {
					'Piping Systems':[],
					'Valves and Actuation':[],
					'Sensors and Instrumentation':[],
					'User Documents':[],
					'QA/QC':[]
				},
				order: ["Piping Systems", "Valves and Actuation", "Sensors and Instrumentation", "User Documents", "QA/QC"]

			};
			this.props.updateProjectData(newState_array);

			this.props.navigation(this.props.pageActive, 'BuildCover', 'CreationHome');
		}).always(()=>{
			this.handleInput('loadingModal',false);
		});
	}

	handleBuildCover(e){
		let requiredFields = {
			'projectname' : false,
			'projectcontact1' : false,
			'projectcontactaddress' : false,
			'projectcontactcompany' : false,
			'projectcontactemail' : false,
			'projectcontactphone' : false
		};
		if(Validation('projectname',utoa(this.props.projectName))){
			requiredFields['projectname'] = true;
		}

		for(var userInput in this.state){
			if(userInput in requiredFields){
				if(Validation(userInput,this.state[userInput]) && this.state[userInput] !=='' ){
					requiredFields[userInput] = true;
				}else{
					requiredFields[userInput] = false;
				}
			}
		}
		
		let requiredState = {'true':0,'false':0};
		Object.keys(requiredFields).forEach(key=>{
			if(!requiredFields[key]){
				requiredState.false+=1;

				var x = document.getElementsByName(key);
				for(var n=0;n<x.length;n++){
					x[n].style.borderColor = "red";
				}

				var y = document.getElementsByName(key+"_label");
				for(var n=0;n<y.length;n++){

					y[n].style.color = "red";
				}
			}else{
				requiredState.true+=1;
				var x = document.getElementsByName(key);
				for(var n=0;n<x.length;n++){
					x[n].style.borderColor = "";
				}

				var y = document.getElementsByName(key+"_label");
				for(var n=0;n<y.length;n++){
					y[n].style.color = "";
				}
			}
		});
		if(requiredState.true === Object.keys(requiredFields).length){
			this.buildCover();
		}else{
			this.handleInput('missingModal',true);
		}
	}

	handleInput(state,newValue){
		if(state === 'projectname'){
			this.props.updateProjectName(state,newValue);
		}else{
			const newState = {};
			newState[state]=newValue;
			this.setState(newState);
		}
	}

	handleTextarea(e){
		this.handleInput(e.target.id,utoa(e.target.value));
	}

	render(){
		if(this.props.pageActive.BuildCover){
			const inputStyle = {borderRadius:'.25rem',width:'100%',padding:'.25rem' };
			const divStyle = 'fl pa3 w-100 normalBackground';
			const input_label = 'fl w-100 ph3 mt1';
			const divStyle_third = 'fl w-25 mt1 f6';
			const divStyle_two_third = 'fl w-75 f6';

			return (
				<div className='zIndex-2'>
					<Fade>
						<MissingFields toggleModal={this.handleInput} state={this.state.missingModal} />
						<Loading toggleModal={this.handleInput} state={this.state.loadingModal} alt="Creating Cover Sheet..."/>
						<div className="w-100 pa4">
							<div className="fullsizeDiv cf br3">
								<div className='fl w-100 optionHeader br3 br--top f3 ba b--dark-gray'>
									<p className='tc w-100'>Cover Sheet</p>
								</div>
								<div className='fl w-100'>
									<div>
										<h4 className='tc ma3'>Project Information</h4>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectname' name='projectname_label'>Project Name<span className='red'>*</span>:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='projectname' name='projectname' style={inputStyle} value={this.props.projectName} onChange={this.handleInput} placeholder='Project Information'/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectinfo1' name='projectinfo1_label'>Line 2:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='projectinfo1' name='projectinfo1' style={inputStyle} onChange={this.handleInput} placeholder='Project Info Line 1'/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectinfo2' name='projectinfo2_label'>Line 3:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='projectinfo2' name='projectinfo2' style={inputStyle} onChange={this.handleInput} placeholder='Project Info Line 2'/>
											</div>
										</div>
										<div className={input_label}>
											<hr/>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectmanager' name='projectmanager_label'>Project Manager:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='projectmanager' name='projectmanager' style={inputStyle} onChange={this.handleInput} placeholder="Project Manager's Name"/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='sitemanager' name='sitemanager_label'>Site Manager's Name:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='sitemanager' name='sitemanager' style={inputStyle} onChange={this.handleInput} placeholder="Site Manager's Name"/>
											</div>
										</div>
										<div className={input_label}>
											<hr/>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='jobno' name='jobno_label'>Job Number:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='jobno' name='jobno' style={inputStyle} onChange={this.handleInput} placeholder="Job Number"/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='jobdescription' name='jobdescription_label'>Job Description:</label>
											</div>
											<div className={divStyle_two_third}>
												<textarea id='jobdescription' name='jobdescription' className='b--black-40 bw1' style={inputStyle} onChange={this.handleTextarea} placeholder='Job Description'/>
											</div>
										</div>
										<div className={input_label}>
											<hr/>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='drawingno' name='drawingno_label'>Drawing No:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='drawingno' name='drawingno' style={inputStyle} onChange={this.handleInput} placeholder="Drawing Numbers"/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='remarks' name='remarks_label'>Remarks:</label>
											</div>
											<div className={divStyle_two_third}>
												<textarea id='remarks' name='remarks' className='b--black-40 bw1' style={inputStyle} onChange={this.handleTextarea} placeholder='Remarks'/>
											</div>
										</div>
									</div>
								</div>
								<div className='fl w-100'>
									<div>
										<h4 className='tc ma3'>Project Contact Info</h4>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectcontact1' name='projectcontact1_label'>Title<span className='red'>*</span>:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='projectcontact1' name='projectcontact1' style={inputStyle} onChange={this.handleInput} placeholder='Project Contact Name'/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectcontactcompany' name='projectcontactcompany_label'>Company<span className='red'>*</span>:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='projectcontactcompany' name='projectcontactcompany' style={inputStyle} onChange={this.handleInput} placeholder="Company Name"/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectcontactaddress' name='projectcontactaddress_label'>Address<span className='red'>*</span>:</label>
											</div>
											<div className={divStyle_two_third}>
												<textarea id='projectcontactaddress' name='projectcontactaddress' className='b--black-40 bw1' style={inputStyle} onChange={this.handleTextarea} placeholder='Address'/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectcontactphone' name='projectcontactphone_label'>Phone<span className='red'>*</span>:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='projectcontactphone' name='projectcontactphone' style={inputStyle} onChange={this.handleInput} placeholder="Phone Number"/>
											</div>
										</div>
										<div className={input_label}>
											<div className={divStyle_third}>
												<label htmlFor='projectcontactemail' name='projectcontactemail_label'>E-mail<span className='red'>*</span>:</label>
											</div>
											<div className={divStyle_two_third}>
												<Input id='projectcontactemail' name='projectcontactemail' style={inputStyle} onChange={this.handleInput} placeholder="E-mail"/>
											</div>
										</div>
									</div>
								</div>	
								<div className='fl w-100'>
									<div>
										<h4 className='tc ma3 mb1'>Additional Project Contacts</h4>
										<div className={input_label}>
											<h5 className='ma0 mb1'>Contact 2</h5>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontact2' name='projectcontact2_label'>Title:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontact2' name='projectcontact2' style={inputStyle} onChange={this.handleInput} placeholder='Project Contact Name'/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactcompany2' name='projectcontactcompany2_label'>Company:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactcompany2' name='projectcontactcompany2' style={inputStyle} onChange={this.handleInput} placeholder="Company Name"/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactaddress2' name='projectcontactaddress2_label'>Address:</label>
												</div>
												<div className={divStyle_two_third}>
													<textarea id='projectcontactaddress2' name='projectcontactaddress2' className='b--black-40 bw1' style={inputStyle} onChange={this.handleTextarea} placeholder='Address'/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactphone2' name='projectcontactphone2_label'>Phone:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactphone2' name='projectcontactphone2' style={inputStyle} onChange={this.handleInput} placeholder="Phone Number"/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactemail2' name='projectcontactemail2_label'>E-mail:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactemail2' name='projectcontactemail2' style={inputStyle} onChange={this.handleInput} placeholder="E-mail"/>
												</div>
											</div>
											<div className={input_label}>
												<hr/>
											</div>

											<h5 className='ma0 mb1'>Contact 3</h5>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontact3' name='projectcontact3_label'>Title:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontact3' name='projectcontact3' style={inputStyle} onChange={this.handleInput} placeholder='Project Contact Name'/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactcompany3' name='projectcontactcompany3_label'>Company:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactcompany3' name='projectcontactcompany3' style={inputStyle} onChange={this.handleInput} placeholder="Company Name"/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactaddress3' name='projectcontactaddress3_label'>Address:</label>
												</div>
												<div className={divStyle_two_third}>
													<textarea id='projectcontactaddress3' name='projectcontactaddress3' className='b--black-40 bw1' style={inputStyle} onChange={this.handleTextarea} placeholder='Address'/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactphone3' name='projectcontactphone3_label'>Phone:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactphone3' name='projectcontactphone3' style={inputStyle} onChange={this.handleInput} placeholder="Phone Number"/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactemail3' name='projectcontactemail3_label'>E-mail:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactemail3' name='projectcontactemail3' style={inputStyle} onChange={this.handleInput} placeholder="E-mail"/>
												</div>
											</div>
											<div className={input_label}>
												<hr/>
											</div>

											<h5 className='ma0 mb1'>Contact 4</h5>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontact4' name='projectcontact4_label'>Title:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontact4' name='projectcontact4' style={inputStyle} onChange={this.handleInput} placeholder='Project Contact Name'/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactcompany4' name='projectcontactcompany4_label'>Company:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactcompany4' name='projectcontactcompany4' style={inputStyle} onChange={this.handleInput} placeholder="Company Name"/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactaddress4' name='projectcontactaddress4_label'>Address:</label>
												</div>
												<div className={divStyle_two_third}>
													<textarea id='projectcontactaddress4' name='projectcontactaddress4' className='b--black-40 bw1' style={inputStyle} onChange={this.handleTextarea} placeholder='Address'/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactphone4' name='projectcontactphone4_label'>Phone:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactphone4' name='projectcontactphone4' style={inputStyle} onChange={this.handleInput} placeholder="Phone Number"/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactemail4' name='projectcontactemail4_label'>E-mail:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactemail4' name='projectcontactemail4' style={inputStyle} onChange={this.handleInput} placeholder="E-mail"/>
												</div>
											</div>
											<div className={input_label}>
												<hr/>
											</div>

											<h5 className='ma0 mb1'>Contact 5</h5>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontact5' name='projectcontact5_label'>Title:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontact5' name='projectcontact5' style={inputStyle} onChange={this.handleInput} placeholder='Project Contact Name'/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactcompany5' name='projectcontactcompany5_label'>Company:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactcompany5' name='projectcontactcompany5' style={inputStyle} onChange={this.handleInput} placeholder="Company Name"/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactaddress5' name='projectcontactaddress5_label'>Address:</label>
												</div>
												<div className={divStyle_two_third}>
													<textarea id='projectcontactaddress5' name='projectcontactaddress5' className='b--black-40 bw1' style={inputStyle} onChange={this.handleTextarea} placeholder='Address'/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactphone5' name='projectcontactphone5_label'>Phone:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactphone5' name='projectcontactphone5' style={inputStyle} onChange={this.handleInput} placeholder="Phone Number"/>
												</div>
											</div>
											<div className={input_label}>
												<div className={divStyle_third}>
													<label htmlFor='projectcontactemail5' name='projectcontactemail5_label'>E-mail:</label>
												</div>
												<div className={divStyle_two_third}>
													<Input id='projectcontactemail5' name='projectcontactemail5' style={inputStyle} onChange={this.handleInput} placeholder="E-mail"/>
												</div>
											</div>
										</div>

										<div className={input_label + ' mt3'}>
											<button className='bg-bottom f5 link dim br3 pv2 mb2 white w-100' onClick={this.handleBuildCover} style={{ backgroundColor : '#1965A3' }}>Build Cover Page</button>
										</div>
									</div>
								</div>

							</div>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

BuildCover.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired,
	projectName: PropTypes.string.isRequired,
	loginInfo: PropTypes.object.isRequired,
	updateLogin: PropTypes.func.isRequired,
	updateProjectName: PropTypes.func.isRequired,
	updateProjectData: PropTypes.func.isRequired,
}