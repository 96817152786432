import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import '../asset/style/Option.css';
import mySubmittal from '../asset/img/My-submittal.jpg';
import createSubmittal from '../asset/img/Create-submittal.jpg';

export default class Option extends Component{
	constructor(props){
		super(props);
		this.navigateMySubmittal = this.navigateMySubmittal.bind(this);
		this.navigateCreateSubmittal = this.navigateCreateSubmittal.bind(this);
	}

	navigateMySubmittal(){
		this.props.navigation(this.props.pageActive,'Option','MySubmittal','azure');
	}

	navigateCreateSubmittal(){
		this.props.navigation(this.props.pageActive,'Option','CreateSubmittal','azure');
	}

	render(){
		
		if(this.props.pageActive.Option){
			return (
				<div>
					<Fade>
						<div className='optionDiv w-auto br3 cf zIndex-2'>
							<div className='fl w-100 optionHeader br3 br--top f3 ba b--dark-gray'>
								<p className='tc w-100' >Select an option to begin</p>
							</div>

							<div className='fl w-100 pa4 '>
								<div className='fl w-50-ns w-100 tr pr3-ns pr2-m'>
									<img className='optionImg shadow-click grow' src={mySubmittal} alt="My-Submittal" onClick={this.navigateMySubmittal} />
								</div>
								<div className='fl w-50-ns w-100 tl pl3-ns pl2-m'>
									<img className='optionImg shadow-click grow' src={createSubmittal} alt="Create-Submittal" onClick={this.navigateCreateSubmittal}/>
								</div>
							</div>
						</div>
					</Fade>
				</div>
			);
		}else{
			return null;
		}
	}
}

Option.propTypes = {
	pageActive: PropTypes.object.isRequired,
	navigation: PropTypes.func.isRequired
}