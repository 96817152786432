import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Modal from 'react-modal';
import '../asset/style/Creation.css';

Modal.setAppElement('#root');

const modalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundBack: 'transparent',
		padding: '0',
	},
	overlay: {
		zIndex: 2
	}
}

export default class DownloadSub extends Component {
	constructor(props){
		super(props);
		this.handleCloseModal = this.handleCloseModal.bind(this);
	}

	handleCloseModal(){
		this.props.toggleModal('downloadModal',false);
	}

	render(){
		return (
			<div>
				<Modal
				isOpen={this.props.state}
				contentLabel="Submittal Download"
				onRequestClose={this.handleCloseModal}
				style={modalStyles}
				shouldCloseOnOverlayClick={false}>
					<div className='fl w-100 cf' style={{width: '50vw'}}>
						<div className='creationHeader br2 br--top f4-ns f6 ba b--dark-gray'>
							<span className='tc w-100'>Submittal Download Link</span>
						</div>
						<div className='pa3 cf'>
							<div className='fl w-100 mt2' style={{wordWrap:'break-word'}}>
								<span style={{fontSize: '12px'}}><i>If the download do not start automatically in a few seconds, please click on the link to access the download URL.</i></span>
							</div>
							<div className='fl w-100 mt2 mb2'>
								<div className='fl w-30-ns w-100'>
									<label htmlFor='downloadLink'><b>Download Link:</b></label>
								</div>
								<div className='fl w-70-ns w-100' style={{wordWrap:'break-word'}}>
									<a href={this.props.downloadLink} className='w-100 cf' download target='_blank'>{this.props.downloadLink}</a>
								</div>
							</div>
							<div className='fl w-100 mt2'>
								<button className='bg-bottom f5 link br3 ph3 pv2 white w-100 bg-dark-gray shadow-click grow' onClick={this.handleCloseModal}>Close Download</button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

DownloadSub.propTypes = {
	toggleModal: PropTypes.func.isRequired,
	state: PropTypes.bool.isRequired,
	downloadLink: PropTypes.string.isRequired,
}